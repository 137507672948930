import { api } from "../services/api";

export const getSong = (id: number) => {
  return api({
    method: "get",
    url: "/song/url",
    params: {
      id,
    },
  });
};

export const getTrackDetails = (ids: string) => {
  return api({
    method: "get",
    url: "/song/detail",
    params: {
      ids,
    },
  });
};
