import React, { useContext, useState } from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import { getSearchResults } from "../api/others";
import { MessageContext } from "../context/MessageProvider";
import { SongContext } from "../context/SongProvider";
import MusicListDetails from "./MusicListDetails";
import { Wrapper } from "./SearchResults.styles";
import _ from "lodash";
import { SearchContext } from "../context/SearchProvider";
import { List } from "./MusicLists.styles";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { BsCaretRight } from "react-icons/bs";

function SearchResults() {
  const { t } = useTranslation();
  const history = useHistory();
  const [offsetIndex, setoffSetIndex] = useState(0);
  const songContext = useContext(SongContext);
  const messageContext = useContext(MessageContext);
  const searchContext = useContext(SearchContext);

  if (!songContext || !messageContext || !searchContext) throw new Error();
  //const { playList, setPlayList, renderList, setRenderList } = songContext;
  const { dispatch } = messageContext;
  const {
    setSearchHistory,
    searchInfo,
    setSearchInfo,
    inputValue,
    setInputValue,
  } = searchContext;

  const { data: songList, isFetching, isLoading, isSuccess } = useQuery(
    ["searchResults-songs", inputValue, offsetIndex],
    () =>
      getSearchResults({
        keywords: inputValue,
        limit: 10,
        offset: offsetIndex * 10,
      }),
    {
      enabled: !!inputValue,
      keepPreviousData: true,
      onSuccess: (data) => {
        // if (data.data.result && !_.isEmpty(data.data.result)) {
        //   setPlayList(data.data.result.songs);
        // } else {
        //   dispatch({ type: "search-error" });
        //   setTimeout(() => {
        //     dispatch({ type: "none" });
        //   }, 3000);
        // }
      },
    }
  );

  const { data: artistAlbums, isSuccess: isArtistAlbumsSuccess } = useQuery(
    ["searchResults-artistAlbums", inputValue],
    () => getSearchResults({ keywords: inputValue, type: 10, limit: 9 }),
    {
      enabled: !!inputValue,
      onSuccess: (data) => {},
    }
  );

  const { data: mvs, isSuccess: isMvsSuccess } = useQuery(
    ["searchResults-mvs", inputValue],
    () => getSearchResults({ keywords: inputValue, type: 1004, limit: 9 }),
    {
      enabled: !!inputValue,
      onSuccess: (data) => {},
    }
  );

  const { data: userAlbums, isSuccess: isUserAlbumsSuccess } = useQuery(
    ["searchResults-userAlbums", inputValue],
    () => getSearchResults({ keywords: inputValue, type: 1000, limit: 9 }),
    {
      enabled: !!inputValue,
      onSuccess: (data) => {},
    }
  );

  //console.log(" userAlbums and artistAlbums and mvs", userAlbums, artistAlbums);

  const handleAlbumClick = (e: React.MouseEvent<HTMLElement>) => {
    const { action, name, id, artistid } = e.currentTarget.dataset;
    history.push({
      pathname: `/playlist/${name}/${id}`,
    });
  };

  const handleMvClick = (e: React.MouseEvent<HTMLElement>) => {
    const { id, artistid } = e.currentTarget.dataset;
    history.push({
      pathname: `/video/${artistid}/${id}`,
    });
  };

  const handlePreviousSongListClick = () => {
    setoffSetIndex((prev) => prev - 1);
  };

  const handleNextSongListClick = () => {
    setoffSetIndex((prev) => prev + 1);
  };

  // console.log(
  //   "songList, songListIndex",
  //   songList,
  //   offsetIndex
  //   // hasNextPage,
  //   // isFetchingNextPage
  // );
  const musicListDetailsProps = {
    albumInfo: undefined,
    renderList: songList?.data?.result.songs,
  };
  return (
    <Wrapper>
      <div className="category-title-container">
        <span className="indicator"></span>
        <span className="title"> {t("search-result.songs")} </span>
      </div>
      {musicListDetailsProps.renderList && (
        <MusicListDetails {...musicListDetailsProps} />
      )}

      <div className="next-previous-buttons">
        {offsetIndex !== 0 && (
          <button
            className="icon"
            disabled={isFetching}
            onClick={handlePreviousSongListClick}
          >
            <AiOutlineLeft />
          </button>
        )}
        {
          <button
            className="icon"
            disabled={isFetching}
            onClick={handleNextSongListClick}
          >
            <AiOutlineRight />
          </button>
        }
      </div>

      <div className="category-title-container">
        <span className="indicator"></span>
        <span className="title">{t("search-result.albums")}</span>
      </div>
      <div className={"playlist-category"}>
        {isArtistAlbumsSuccess &&
          artistAlbums?.data.result &&
          artistAlbums?.data.result.albums?.map(
            (playlist: { id: number; name: string; picUrl: string }) => (
              <List
                key={playlist.id}
                data-name="album"
                data-id={playlist.id}
                //data-artistid={playlist.artistId}
                //data-action="album"
                onClick={handleAlbumClick}
              >
                <div className="image-container">
                  <img
                    src={playlist.picUrl + "?param=300y300"}
                    alt="artist albums"
                  />
                </div>
                <div className="short-description">{playlist.name}</div>
              </List>
            )
          )}
      </div>

      <div className="category-title-container">
        <span className="indicator"></span>
        <span className="title">{t("search-result.mvs")}</span>
      </div>
      <div className={"playlist-category"}>
        {isMvsSuccess &&
          mvs?.data.result &&
          mvs?.data.result.mvs?.map(
            (playlist: {
              id: number;
              name: string;
              cover: string;
              artistId: number;
            }) => (
              <List
                key={playlist.id}
                // data-name={playlist.name}
                data-id={playlist.id}
                data-artistid={playlist.artistId}
                //data-action="mv"
                onClick={handleMvClick}
              >
                <div className="image-container">
                  <img src={playlist.cover + "?param=300y300"} alt="mv" />
                  <div className="video-icon">
                    <BsCaretRight />{" "}
                  </div>
                </div>
                <div className="short-description">{playlist.name}</div>
              </List>
            )
          )}
      </div>

      <div className="category-title-container">
        <span className="indicator"></span>
        <span className="title">{t("search-result.playlists")}</span>
      </div>
      <div className={"playlist-category"}>
        {isUserAlbumsSuccess &&
          userAlbums?.data.result &&
          userAlbums?.data.result.playlists?.map(
            (playlist: { id: number; name: string; coverImgUrl: string }) => (
              <List
                key={playlist.id}
                data-name="playlist"
                data-id={playlist.id}
                //data-artistid={playlist.artistId}
                //data-action="album"
                onClick={handleAlbumClick}
              >
                <div className="image-container">
                  <img
                    src={playlist.coverImgUrl + "?param=300y300"}
                    alt="user album"
                  />
                </div>
                <div className="short-description">{playlist.name}</div>
              </List>
            )
          )}
      </div>
    </Wrapper>
  );
}

export default SearchResults;
