import React from "react";
import { AiOutlineFire, AiOutlineClose } from "react-icons/ai";
import { RiWalkLine, RiArrowDownLine } from "react-icons/ri";
import dayjs from "dayjs";
import { Comment } from "./Comments";
import { Wrapper } from "./VideoComments.styles";
import { useTranslation } from "react-i18next";

// export interface CommentsType {
//   content: string;
//   time: number;
//   likedCount: number;
//   commentId: number;
//   user: {
//     nickname: string;
//     avatarUrl: string;
//   };
// }

interface PropType {
  comments: Comment[];
  handleClick: (e: React.MouseEvent<HTMLElement>) => void;
}

function VideoComments({ comments, handleClick }: PropType) {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div
        className="close-comments"
        data-action="close-comments"
        onClick={handleClick}
      >
        <AiOutlineClose />
      </div>
      <ul>
        {comments.length ? (
          <>
            {comments.map((comment, index) => (
              <li key={comment.commentId}>
                <div className="user-avatar">
                  <img src={comment.user.avatarUrl + "?param=60y60"} />
                </div>
                <div className="comment-container">
                  <div className="comment">
                    <span className="user-name">{comment.user.nickname}:</span>
                    <span>{comment.content}</span>
                  </div>
                  <div className="dateInfo">
                    <span>
                      {dayjs(comment.time).format("YYYY-MM-DD HH:mm")}
                    </span>
                    <span className="thumb-up">
                      <AiOutlineFire />
                      {comment.likedCount}
                    </span>
                  </div>
                </div>
              </li>
            ))}
            {/* <div ref={bottomRef} className="bottom-ref">
              <RiArrowDownLine />
            </div> */}
          </>
        ) : (
          <div className="loading-indicator">
            <span>
              <RiWalkLine />
            </span>
            <p>{t("common.loading")}</p>
          </div>
        )}
      </ul>
    </Wrapper>
  );
}

export default VideoComments;
