import React from "react";
import MainContainer from "../components/layouts/MainContainer";
import SearchInfo from "../components/SearchInfo";

function Search() {
  return (
    <MainContainer>
      <SearchInfo />
    </MainContainer>
  );
}

export default Search;
