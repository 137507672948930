import React from "react";
import Carousel from "../components/Carousel";
import MainContainer from "../components/layouts/MainContainer";
import MusicLists from "../components/MusicLists";
import SearchBar from "../components/SearchBar";

function Home() {
  return (
    <MainContainer>
      <Carousel />
      <MusicLists />
    </MainContainer>
  );
}

export default Home;
