import React from "react";
import VideoPlayer from "../components/VideoPlayer";

function Video() {
  return (
    <>
      <VideoPlayer />
    </>
  );
}

export default Video;
