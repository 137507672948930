import React, { useContext } from "react";
import MainContainer from "../components/layouts/MainContainer";
import MusicListDetails from "../components/MusicListDetails";
import { SongContext } from "../context/SongProvider";

function Favorite() {
  const songContext = useContext(SongContext);

  if (!songContext) throw new Error();
  const {
    albumInfo,

    favoriteList,
  } = songContext;

  const musicListDetailsProps = {
    albumInfo,
    renderList: favoriteList,
  };
  return (
    <MainContainer>
      <MusicListDetails {...musicListDetailsProps} />
    </MainContainer>
  );
}

export default Favorite;
