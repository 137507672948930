import React, { useContext, useEffect, useRef, useState } from "react";
import { BsInbox } from "react-icons/bs";
import { AiOutlineClose, AiOutlineMore } from "react-icons/ai";
import { GiJumpingDog } from "react-icons/gi";
import { IoSettingsOutline } from "react-icons/io5";
import { useHistory, useLocation } from "react-router-dom";
import { SongContext } from "../context/SongProvider";
import { Wrapper } from "./UserProfile.styles";
import { getFromIndexedDB, writeToIndexedDB } from "../services/indexedDB";
import { useTranslation } from "react-i18next";
import { api } from "../services/api";
import { MessageContext } from "../context/MessageProvider";

const indexedDBInfo = {
  datastore: "media",
  key: "avatar",
};

function UserProfile() {
  const { t, i18n } = useTranslation();
  // const localProfileSrc = localStorage.getItem("profileSrc");
  const [profileSrc, setProfileSrc] = useState("/images/blank-profile.webp");
  const [showImportExport, setShowImportExport] = useState(false);
  const [fileSrc, setFileSrc] = useState("");
  const [isSyncDialogOpen, setIsSyncDialogOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [albumInfoDialogOpen, setAlbumInfoDialogOpen] = useState<{[key: string|number]: boolean}>({});
  const [langSwitchClicked, setLangSwitchClicked] = useState(false);

  const fileRef = useRef<HTMLAnchorElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  // localStorage.setItem("profileSrc", JSON.stringify(profileSrc));

  // console.log("profileSrc", typeof profileSrc);

  const history = useHistory();
  const location = useLocation();
  const songContext = useContext(SongContext);
  const messageContext = useContext(MessageContext);

  if (!songContext || !messageContext) throw new Error();

  const {
    favoriteList,
    setFavoriteList,
    favoriteAlbums,
    setFavoriteAlbums,
    setCurrentList,
    setPlayList,
    playListId,
    setPlayListId,
    historyList,
    setAlbumInfo,
    analyticData,
    setAnalyticData,
  } = songContext;

  const { dispatch } = messageContext;
  //console.log("analyticData", analyticData);

  useEffect(() => {
    const fetchFromIndexedDB = async () => {
      try {
        const response = await getFromIndexedDB(
          indexedDBInfo.datastore,
          indexedDBInfo.key
        );
        //console.log("response is", response);
        response && setProfileSrc(response.data);
      } catch (error) {
        console.error("error ", error);
      }
    };

    fetchFromIndexedDB();
  }, []);

  // Used to export playlist as json file
  useEffect(() => {
    if (fileRef.current) {
      fileSrc && fileRef.current.click();
    }
  }, [fileSrc]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const { action, id } = e.currentTarget.dataset;
    switch (action) {
      case "fav-list":
        // setPlayList(favoriteList);
        // Used to show the header with image and description
        setAlbumInfo({
          id: 1,
          name: t("profile.favorite"),
          description: t("profile.favorite"),
          cover: favoriteList[0].al.picUrl,
          color: "#393e46",
        });
        history.push("/favorite");
        break;
      case "fav-album":
        // setPlayList(favoriteList);

        if (id) {
          //setPlayListId(+id);  // now using id from useParams in MusicListDetails component
          setPlayList([]); // Reset playlist before fetching new playlist
          setAlbumInfo(undefined); // Reset albumInfo before jumping to album details page
          history.push({
            pathname: `/playlist/favorite/${id}`,
          });
        }

        break;
      case "history-album":
        //setPlayList(historyList);
        setAlbumInfo({
          id: 2,
          name: t("profile.history"),
          description: t("profile.history"),
          cover: historyList[0].al.picUrl,
          color: "#393e46",
        });
        history.push("/history");
        break;
      case "export":
        const localData = JSON.stringify(
          localStorage,
          ["currentList", "favoriteList"],
          2
        );
        const result = URL.createObjectURL(
          new Blob([localData], { type: "application/json" })
        );
        setTimeout(() => {
          URL.revokeObjectURL(result);
        }, 1000);
        setFileSrc(result);
        break;
      case "lang-switch":
        // setLangSwitchClicked((prev) => !prev);
        if (i18n.language !== "zh") {
          // setLangSwitchClicked(true);
          i18n.changeLanguage("zh");
        } else {
          // setLangSwitchClicked(false);
          i18n.changeLanguage("en");
        }
        break;
      case "setting-icon":
        setShowImportExport((prev) => !prev);
        break;
      case "sync-dialog":
        setIsSyncDialogOpen(true);
        break;
      case "close-icon":
        setIsSyncDialogOpen(false);
        break;
      case "album-info-icon":
        if (id) {
          setAlbumInfoDialogOpen((prev) => ({ [id]: !prev[id] }));
        }
        break;
      case "delete-fav-album":
        if (id) {
          setFavoriteAlbums((prev) => prev.filter((item) => item.id !== +id));
        }
        break;
      case "analytics":
        history.push("analytics");
        break;
      default:
        console.error("Error, no such case");
    }
  };

  const handleDoubleClick = () => {
    // setShowImportExport((prev) => !prev);

    // const browserLanguage = localStorage.getItem("i18nextLng")?.split("-")[0];
    //console.log("i18n.language", i18n.language);

    // if (i18n.language !== "zh") {
    //   i18n.changeLanguage("zh");
    // } else {
    //   i18n.changeLanguage("en");
    // }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files, id } = e.currentTarget;
    // console.log(files);
    if (files && files.length) {
      const reader = new FileReader();
      switch (id) {
        case "avatar-upload":
          reader.readAsDataURL(files[0]);
          reader.onload = (e) => {
            // if (e.target && e.target.result) {
            //   console.log("e.target.result", e.target.result, reader.result);
            // }
            if (typeof reader.result === "string") {
              setProfileSrc(reader.result);
              if ("indexedDB" in window) {
                writeToIndexedDB(
                  indexedDBInfo.datastore,
                  { data: reader.result },
                  indexedDBInfo.key
                );
              }
            }
          };
          break;
        case "data-import":
          reader.readAsText(files[0]);
          reader.onload = (e) => {
            if (typeof reader.result === "string") {
              //console.log(typeof reader.result, JSON.parse(reader.result));
              const fileData = JSON.parse(reader.result);
              Object.entries(fileData).map(([key, value]) => {
                if (typeof value === "string") {
                  if (key === "favoriteList") {
                    setFavoriteList(JSON.parse(value));
                  } else if (key === "currentList") {
                    setCurrentList(JSON.parse(value));
                  }
                }
              });
            }
          };
          break;
        case "email-input":
          // setEmail(e.currentTarget.value);
          break;
        default:
          console.error("Error, no such case");
      }
    }
  };

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    const localFavoriteList = localStorage.getItem("favoriteList");
    setIsPending(true);
    e.preventDefault();
    if (emailRef.current) {
      try {
        const response = await api.post("/sync-email", {
          email: emailRef.current?.value,
          favoriteList: localFavoriteList,
        });
        //console.log("response", response);
        if (response.status === 201) {
          setFavoriteList(response.data.favoriteList);
          setIsPending(false);
          setIsSyncDialogOpen(false);
        }
      } catch (error: any) {
        console.error(error?.response?.message || error.message);
      }
    }
  };

  return (
    <Wrapper>
      <div className="avatar" onDoubleClick={handleDoubleClick}>
        {/* <h4>Your Favorites</h4> */}
        <label htmlFor="avatar-upload">
          <img src={profileSrc} alt="profile" />
        </label>
        <input
          type="file"
          accept="image/*"
          id="avatar-upload"
          onChange={handleChange}
        />
      </div>
      <div
        className="lang-switch"
        data-action="lang-switch"
        onClick={handleClick}
      >
        <span
          style={{
            color: i18n.language === "zh" ? "#fff" : "#ccc",
            background: i18n.language === "zh" ? "#000000cc" : "transparent",
          }}
        >
          中
        </span>
        /
        <span
          style={{
            color: i18n.language === "zh" ? "#ccc" : "#fff",
            background: i18n.language === "zh" ? "transparent" : "#000000cc",
          }}
        >
          EN
        </span>
      </div>
      <div
        className="setting-icon"
        data-action="setting-icon"
        onClick={handleClick}
      >
        <IoSettingsOutline />
      </div>
      {showImportExport && (
        <div className="import-export">
          <h5>{t("profile.playlist-implementation")} </h5>
          <button data-action="export" onClick={handleClick}>
            {t("profile.export")}
          </button>
          <label htmlFor="data-import"> {t("profile.import")}</label>
          <input
            type="file"
            accept="application/json"
            id="data-import"
            onChange={handleChange}
          />
          <button data-action="sync-dialog" onClick={handleClick}>
            {t("profile.sync")}
          </button>
        </div>
      )}
      {favoriteList.length || favoriteAlbums.length || historyList.length ? (
        <div className="container">
          <div className="album-title"> {t("profile.favorite")}</div>
          {favoriteList.length || favoriteAlbums.length ? (
            <div className="fav-container">
              {favoriteList.length ? (
                <div className="fav-list">
                  <div
                    className="image-container"
                    data-action="fav-list"
                    onClick={handleClick}
                  >
                    <img
                      src={favoriteList[0].al.picUrl + "?param=300y300"}
                      alt="fav playlist"
                    />
                  </div>
                </div>
              ) : null}
              {favoriteAlbums.length
                ? favoriteAlbums.map((favoriteAlbum) => (
                    <div className="fav-albums" key={favoriteAlbum.id}>
                      <div
                        className="album-info-icon"
                        data-action="album-info-icon"
                        data-id={favoriteAlbum.id}
                        onClick={handleClick}
                      >
                        <AiOutlineMore />
                        {albumInfoDialogOpen[favoriteAlbum.id] && (
                          <div
                            className="delete-fav-album"
                            data-action="delete-fav-album"
                            data-id={favoriteAlbum.id}
                            onClick={handleClick}
                          >
                            {t("profile.delete")}
                          </div>
                        )}
                      </div>

                      <div
                        className="image-container"
                        data-action="fav-album"
                        data-id={favoriteAlbum.id}
                        onClick={handleClick}
                      >
                        <img
                          src={favoriteAlbum.cover + "?param=300y300"}
                          alt="fav playlist"
                        />
                      </div>
                    </div>
                  ))
                : null}
            </div>
          ) : (
            <p>{t("profile.no-favorite")}</p>
          )}
          <div className="history-analytics-container">
            {historyList.length ? (
              <div className="history-container">
                <div className="album-title"> {t("profile.history")}</div>
                <div className="history-list">
                  <div
                    className="image-container"
                    data-action="history-album"
                    onClick={handleClick}
                  >
                    <img
                      src={historyList[0].al.picUrl + "?param=300y300"}
                      alt="history playlist"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <p>{t("profile.no-history")}</p>
            )}
            {analyticData.length ? (
              <div className="analytics-container">
                <div className="album-title"> {t("profile.analytics")}</div>
                <div
                  className="analytics"
                  data-action="analytics"
                  onClick={handleClick}
                >
                  <ul>
                    {analyticData
                      .sort((a, b) => b.playCount - a.playCount)
                      .slice(0, 3)
                      .map((item) => (
                        <li
                          key={item.id}
                          style={{
                            background: `url(${
                              item.image + "?param=100y100"
                            }) no-repeat center/cover`,
                          }}
                        >
                          {/* <span>{item.name}</span> */}
                          <span>{item.playCount}</span>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="no-record-indicator">
          <span>
            <BsInbox />
          </span>

          <p>{t("profile.no-record")}</p>
        </div>
      )}
      {/* {historyList.length ? (
        <div className="delete" data-action="delete-all" onClick={handleClick}>
          <AiOutlineDelete />
        </div>
      ) : null} */}
      {/* <div>
        <iframe
          src="https://nrhchat.tk"
          width="100%"
          height="800"
          title="nrh chat"
        />
      </div> */}
      <a
        ref={fileRef}
        href={fileSrc}
        download="nrh-music.json"
        target="_blank"
        rel="noreferrer"
      />
      {isSyncDialogOpen ? (
        <div className="sync-dialog">
          <div
            className="close-icon"
            data-action="close-icon"
            onClick={handleClick}
          >
            <AiOutlineClose />
          </div>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              required
              name="email-input"
              placeholder={t("profile.placeholder")}
              // value={email}
              // onChange={handleChange}
              ref={emailRef}
            />
            <button data-action="sync" disabled={isPending}>
              {t("profile.sync")}
            </button>
          </form>
          <div className="pending">{isPending ? <GiJumpingDog /> : null}</div>
        </div>
      ) : null}
    </Wrapper>
  );
}

export default UserProfile;
