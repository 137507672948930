import styled from "styled-components";
import { colors, loadingAnimation } from "../utils/styles/global.styles";

type WrapperType = {
  albumImage: string;
};
// background-image: ${({ albumImage }) => `url(${albumImage})`};

export const Wrapper = styled.div`
  /* margin: 50px 0; */
  position: relative;
  //padding: 10px 0;
  //margin: 50px 0;
  width: inherit;
  max-width: inherit;

  /* &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
    background-size: 100%;
    opacity: 0.5;
    filter: blur(10px);
  } */
  .header-container {
    display: flex;
    padding: 15px;
    /* justify-content: center;
    align-items: center; */
    background: #393e46; //#4a5464;
    min-height: 195px;
    width: inherit;
    max-width: inherit;
    /* overflow: auto; */
    //margin-top: -10px;
  }

  .image-container {
    flex: 1 0 40%;
    height: 150px;
    padding: 0 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .text-container {
    flex: 60%;
    padding-left: 10px;

    .title {
      color: #fff;
      padding-bottom: 30px;
    }

    .description {
      color: #ccc;
      font-size: 12px;
      /* width: 50vw; */
      /* white-space: nowrap;
      text-overflow: ellipsis; */
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }

  .control {
    padding: 10px 20px;
    position: sticky;
    top: 50px;
    background: #fff; //#eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px 15px 0 0;
  }

  .control-icon-text {
    display: flex;
    align-items: center;

    svg {
      font-size: 25px;
      /* vertical-align: middle; */
      padding-right: 5px;
    }

    &:active {
      background: ${colors.clickEffect};
      transition: 0.1s;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;

    .current-indicator-icon {
      font-size: 20px;
      color: #ffb900;
    }

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      cursor: pointer;

      .song-info {
        flex: 80%;
        display: flex;

        & > div:nth-child(1) {
          flex: 10%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        & > div:nth-child(2) {
          flex: 85%;

          .song-title {
            font-size: 15px;
          }

          .artist-name {
            font-size: 12px;
          }
        }

        &:active {
          background: ${colors.clickEffect};
          transition: 0.1s;
        }
      }

      .play-video {
        flex: 10%;
        text-align: center;
        font-size: 25px;
        color: #4a5464;

        &:active {
          background: ${colors.clickEffect};
          transition: 0.1s;
        }
      }

      .dot-details {
        flex: 10%;
        text-align: center;
        border-radius: 50%;

        &:active {
          background: ${colors.clickEffect};
          transition: 0.1s;
        }
      }
    }
  }

  .loading-indicator {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-size: 50px;
      position: relative;
      animation: ${loadingAnimation} 6s linear 0s infinite;
    }
  }

  .no-record-indicator {
    flex: 90%;
    /* height: 50vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-size: 30px;
    }
  }
`;
