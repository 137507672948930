import styled from "styled-components";
import { randomColor } from "../utils/generator";
import { colors } from "../utils/styles/global.styles";

export const Wrapper = styled.div<{ albumImage: string }>`
  position: fixed;
  // top: 50px; // The height search bar */
  top: 0;
  bottom: 0;
  width: inherit;
  max-width: inherit;
  height: 100%;
  //calc(100vh - 105px); // 100vh minus the height of search bar 50px and the height of paly bar 55px
  background: ${({ albumImage }) =>
    `linear-gradient(rgba(100, 100, 100, 0.6), rgba(100, 100, 100, 0.6)),
  url(${albumImage + "?param=900y900"})`};
  background-size: cover;
  background-attachment: fixed;
  overflow: auto;
  z-index: 2;

  .container {
    /* display: flex; */
    padding: 20px;
    text-align: left;
    min-height: fill-available; //calc(100% - 55px);
    color: #fff;
    backdrop-filter: blur(30px);

    .header {
      //width: 90%;
      display: flex;
      position: relative;
      padding: 10px 0;
      font-size: 25px;
      justify-content: space-between;
      align-items: center;

      .back-icon {
        font-size: 30px;
        flex: 5%;
        cursor: pointer;
        border-radius: 30%;

        &:active {
          background: ${colors.clickEffect};

          transition: 0.1s;
        }
      }

      .title {
        flex: 95%;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
      }
    }

    .lyrics-container {
      height: 60vh; //calc(100vh - 80px);
      margin: 20px 0;
      overflow: auto;
      transition: all 600ms;
      font-size: 14px;
    }

    .loading-indicator {
      height: 55vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #ccc;

      span {
        font-size: 50px;
      }
    }
  }
`;

export const LyricList = styled.ul`
  padding: 0;
  list-style: none;
  text-align: center;

  li {
    /* padding: 5px 0; */
    line-height: 30px;
    min-height: 30px;

    // color: #ddd; //${colors.lightGreen};
  }
`;

export const Lyric = styled.li<{ isHighlight: boolean }>`
  /* position: relative;
  top: ${({ isHighlight }) => (isHighlight ? "50vh" : 0)}; */
  color: ${({ isHighlight }) => (isHighlight ? colors.green : "#ddd")};
`;

export const ControlContainer = styled.div`
  width: 100%;
  padding-top: 10px;

  .icons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 22px;
    cursor: pointer;
    padding: 15px 0;

    .active {
      color: red;
    }
  }

  .music-progress {
    input {
      width: 100%;
      appearance: none;
      height: 2px;
      display: block;

      /* &::-webkit-slider-runnable-track {
        appearance: none;
        height: 3px;
        background: ${randomColor()};
      } */

      &::-webkit-slider-thumb {
        appearance: none;
        height: 10px;
        width: 10px;
        border-radius: 10px;
        background: ${randomColor()};
        cursor: pointer;
      }

      /* &::-webkit-progress-bar {
        color: red;
        background: #ccc;
      }

      &::-webkit-progress-value {
        background: ${randomColor()};
      } */
    }

    .music-duration {
      font-size: 10px;
      display: flex;
      justify-content: space-between;
      padding: 2px;
    }
  }

  .music-control {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 50px;
    width: 100%;

    div {
      flex: 0 0 16%;
      font-size: 20px;
      border-radius: 30%;
      text-align: center;

      /* &:active {
        background: ${colors.clickEffect};
        transition: 0.1s;
      } */
    }

    .play-control {
      display: flex;
      justify-content: center;
      align-items: center;
      /* text-align: center; */
      /* margin: 0 auto; */

      .play-pause {
        min-width: 40px;
        height: 40px;
        font-size: 30px;
        border: 1px solid ${colors.white};
        border-radius: 50%;

        svg {
          padding-left: 2px;
          padding-top: 4px;
        }
      }
    }
  }
`;
