import React from "react";
import ThreeDCube from "../components/ThreeDCube";

function Analytics() {
  return (
    <>
      <ThreeDCube />
    </>
  );
}

export default Analytics;
