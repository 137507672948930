import React from "react";
import Comments from "../components/Comments";

function Comment() {
  return (
    <>
      <Comments />
    </>
  );
}

export default Comment;
