import styled, { css, keyframes } from "styled-components";
import { randomColor } from "../utils/generator";

const centeredBaseStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const commentMove = keyframes`
   /* 0%  {right:-120px}
  20%  {right:80px}
  40%  {right:180px;}
  60%  {right:280px;}
  80% {right:380px;}
  100%  {right:480px;} */
  from {
    //right: -400px;
    left:100%;
    //transform:translateX(100%);
  }

  to{
    //right: 400px;
    left:0;
    transform:translateX(-100%);
  }
`;

export const Wrapper = styled.div<{
  isPlaying: boolean;
  isCommentShowing: boolean;
}>`
  position: relative;
  top: 0;
  bottom: 0;
  height: 100vh;
  background: #000;
  color: #fff;
  z-index: 50;
  width: inherit;
  max-width: inherit;

  /* overflow: hidden; */
  --plyr-color-main: rgb(0, 0, 0, 0.5);
  --plyr-range-fill-background: ${randomColor()};
  /* --plyr-progress-loading-size: 3px; */
  --plyr-range-thumb-height: 8px;
  --plyr-range-track-height: 3px;
  --plyr-control-icon-size: 22px;

  .plyr__control--overlaid {
    pointer-events: none;
  }

  .video-player {
    width: inherit;
    max-width: inherit;
  }

  .plyr {
    position: fixed;
    top: ${({ isCommentShowing }) => (isCommentShowing ? "-50vh" : 0)};
    bottom: 0;
    width: inherit;
    max-width: inherit;
    z-index: 100;
    // height: 100vh; //calc(100vh - 50px);
  }

  .header {
    display: flex;
    position: relative;
    z-index: 200;
    color: #bbb;
    padding: 10px;
    font-size: 25px;
    justify-content: space-between;
  }

  .setting-menu {
    position: absolute;
    right: 10px;
    z-index: 300;
    background: #eee;
    color: #000;
    padding: 10px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        text-align: justify;
      }
    }
  }

  .comment-area {
    position: absolute;
    z-index: 200;
    color: #ddd;
    /* top: 50px;  */
    font-size: 15px;
    /* width: max-content; */
    width: inherit;
    max-width: inherit;
    text-align: right;
    line-height: 1.5;

    span {
      margin-left: 50px;
    }

    .comment1 {
      //position: absolute; //FIXME: will cause animation issue with horizontal scroll
      top: 60px;
      width: inherit;
      max-width: inherit;
      animation: ${commentMove} 10s linear 0.5s infinite forwards;
      animation-play-state: ${({ isPlaying }) =>
        isPlaying ? "running" : "paused "};
    }

    .comment2 {
      /* position: absolute; */
      top: 100px;
      width: inherit;
      //max-width: inherit;
      animation: ${commentMove} 6s linear 0.5s infinite forwards;
      animation-play-state: ${({ isPlaying }) =>
        isPlaying ? "running" : "paused "};
    }

    .comment3 {
      /* position: inherit; */
      top: 150px;
      width: inherit;
      animation: ${commentMove} 8s linear 0.5s infinite forwards;
      animation-play-state: ${({ isPlaying }) =>
        isPlaying ? "running" : "paused "};
    }
  }

  .loading-indicator {
    ${centeredBaseStyle};
  }

  .pause-icon {
    ${centeredBaseStyle};
    z-index: 200;
    color: #fff;
    font-size: 60px;
    display: ${({ isPlaying }) => (isPlaying ? "none" : "block")};
  }

  .mv-info {
    position: absolute;
    bottom: 50px;
    z-index: 200;
    color: #bbb;
    padding: 10px;

    .song-artist {
      font-size: 14px;
      color: #ddd;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }

    .artist-avatar {
      margin-right: 10px;
      img {
        object-fit: cover;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 3px solid #fff;
        border-color: ${randomColor()} black ${randomColor()} ${randomColor()};
      }
    }

    .artist-name {
      /* background: linear-gradient(0deg, red, 10%, #ffba00);
      background-position: 1px 15px;
      background-repeat: no-repeat; */
      padding-bottom: 5px;
      border-bottom: 2px solid ${randomColor()};
    }

    .song-name {
      display: flex;
      align-items: center;
      font-size: 14px;

      .song-icon {
        margin-right: 10px;
        margin-top: 5px;
      }
    }
  }

  .mv-action-icons {
    position: absolute;
    bottom: 60px;
    right: 10px;
    z-index: 200;
    color: #bbb;
    padding: 10px;

    .comment-icon {
      font-size: 20px;
    }
  }
`;

// export const PauseIcon = styled.div<{ isPlaying: boolean }>`
//   ${centeredBaseStyle};
//   z-index: 200;
//   color: #fff;
//   font-size: 60px;
//   display: ${({ isPlaying }) => (isPlaying ? "none" : "block")};
// `;
