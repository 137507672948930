import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { BsThreeDotsVertical, BsInbox } from "react-icons/bs";
import {
  RiBarChartFill,
  RiFlightTakeoffLine,
  RiPlayCircleLine,
  RiYoutubeLine,
} from "react-icons/ri";
import { AiOutlineDelete, AiOutlineFolderAdd } from "react-icons/ai";
import { Wrapper } from "./MusicListDetails.styles";
import { AlbumInfo, PlayListItem, SongContext } from "../context/SongProvider";
import { api } from "../services/api";
import { MessageContext } from "../context/MessageProvider";
import { checkMusicAvailability } from "../utils/checkMusicAvailability";
import { useTranslation } from "react-i18next";
import Vibrant from "node-vibrant";
import { getPlaylistDetails } from "../api/playlist";
import { getTrackDetails } from "../api/track";
import {
  getArtistAllPlaylist,
  getArtistIdentity,
  getArtistTopPlaylist,
} from "../api/artist";
import { categoryList, Params } from "../pages/PlayList";

// interface PlayListItem {
//   id: number;
//   name: string;
//   ar: {
//     [key: string]: string;
//   }[];
//   al: {
//     [key: string]: string;
//   };
//   mv: number;
// }

interface Props {
  albumInfo: AlbumInfo | undefined;
  renderList: PlayListItem[];
  //categoryList?: string[];
  //params?: Params;
}

interface LocationType {
  pathname: string;
  search: string;
  state: {
    playlistId: number;
    category: string;
  };
}

//const categoryList = ["trends", "recommended", "hot", "favorite"];

function MusicListDetails({
  albumInfo,
  renderList,
}: //categoryList,
//params,
Props) {
  const { t } = useTranslation();
  // const [albumImage, setAlbumImage] = useState("");
  // const [renderList, setRenderList] = useState<PlayListItem[]>([]);
  // const [dynamicColor, setDynamicColor] = useState("");

  // const songRef = useRef<HTMLLIElement>(null);
  const topElementRef = useRef<HTMLDivElement>(null);

  const songContext = useContext(SongContext);
  const messageContext = useContext(MessageContext);

  if (!songContext || !messageContext) throw new Error();
  const {
    //albumInfo,
    setAlbumInfo,
    song,
    setSong,
    playList,
    setPlayList,
    //renderList,
    setRenderList,
    currentList,
    setCurrentList,
    clickedId,
    setClickedId,
    setIsFirstTime,
    songActionInfo,
    setSongActionInfo,
    // playListId,
    // setPlayListId,
    favoriteList,
    favoriteAlbums,
    setFavoriteAlbums,
    historyList,
    setClickedSong,
  } = songContext;

  const { dispatch } = messageContext;

  const location: LocationType = useLocation();
  const history = useHistory();
  const params = useParams<{ type: string; id: string }>();
  //const playListId = +params.id;

  //console.log("playListId", playListId, params);

  // const { status, data: playlistInfo, error } = useQuery(
  //   ["playlistInfo", playListId],
  //   () => getPlaylistDetails(playListId),
  //   {
  //     enabled: categoryList.includes(params.type),
  //     onSuccess: (data) => {
  //       const { id, name, description, coverImgUrl } = data.data.playlist;
  //       setAlbumInfo({ id, name, description, cover: coverImgUrl });
  //     },
  //   }
  // );

  // console.log("playlistInfo", playlistInfo);
  // const trackIds: number[] = playlistInfo?.data.playlist.trackIds.map(
  //   (item: { id: number }) => item.id
  // );
  // //console.log(status, location.pathname);
  // const { isIdle, data: playlistDetails } = useQuery(
  //   ["playlistDetails", trackIds],
  //   () => getTrackDetails(trackIds.join(",")),
  //   {
  //     enabled: !!trackIds,
  //     onSuccess: (data) => {
  //       setPlayList(data.data.songs);
  //     },
  //   }
  // );

  // const { data: artistPlaylist } = useQuery(
  //   ["artistPlaylist", playListId],
  //   () => getArtistTopPlaylist(playListId),
  //   {
  //     enabled: params.type === "artists",
  //     onSuccess: (data) => {
  //       setPlayList(data.data.songs);
  //     },
  //   }
  // );

  // const { data } = useQuery(
  //   ["artistInfo", playListId],
  //   () => getArtistIdentity(playListId),
  //   {
  //     enabled: params.type === "artists",
  //     onSuccess: (data) => {
  //       const { id, name, briefDesc, cover } = data.data.data.artist;
  //       setAlbumInfo({ id, name, description: briefDesc, cover });
  //     },
  //   }
  // );

  //console.log("albumInfo", albumInfo);

  // useEffect(() => {
  //   if (
  //     location.pathname.includes("/playlist") ||
  //     location.pathname.includes("/search-result")
  //   ) {
  //     return setRenderList(playList);
  //   }

  //   if (location.pathname === "/favorite") {
  //     return setRenderList(favoriteList);
  //   }

  //   if (location.pathname === "/history") {
  //     return setRenderList(historyList);
  //   }
  // }, [playList, favoriteList, historyList]);

  // console.log("renderList is", renderList);

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, [playList]);

  useEffect(() => {
    if (albumInfo?.cover) {
      const vibrant = new Vibrant(albumInfo.cover);
      vibrant
        .getPalette()
        .then((palette: any) =>
          setAlbumInfo({ ...albumInfo, color: palette?.DarkMuted?.hex })
        );
    }
  }, [albumInfo?.cover]);

  // const throttle = (
  //   fun: (e: React.MouseEvent<HTMLElement>) => void,
  //   delay: number
  // ) => {
  //   let timeoutId = false;

  //   return (e: React.MouseEvent<HTMLElement>) => {
  //     if (timeoutId) return;
  //     timeoutId = !!setTimeout(() => {
  //       fun(e);
  //       timeoutId = false;
  //     }, delay);
  //   };
  // };

  const handleClick = async (e: React.MouseEvent<HTMLElement>) => {
    setIsFirstTime(false); // Used to set the play-pause icon to play in PlayBar.js

    const { action } = e.currentTarget.dataset;

    switch (action) {
      case "expand-description":
        e.stopPropagation();
        e.currentTarget.style["-webkit-line-clamp"] = 100;
        e.currentTarget.style["padding-bottom"] = "15px";
        break;
      case "open-description-screen":
        // history.push("/description");
        history.push({
          pathname: location.pathname,
          search: "?playlist-description",
          state: { background: location },
        });
        break;
      case "play-all":
        if (renderList.length) {
          setCurrentList(renderList);
          const id = renderList[0].id;
          setClickedSong({
            id,
            index: 0,
            name: "",
            artists: "",
            artistId: 0,
            image: "",
            album: "",
            mvId: 0,
          }); // Used by fetchSong function in PlayBar component
        }
        break;
      case "delete-all":
        history.push({
          pathname: location.pathname,
          search: "?delete-all",
          state: { background: location, originPage: location.pathname },
        });
        break;
      case "fav-album":
        if (albumInfo) {
          // Check if the album exist in favorite albums
          if (favoriteAlbums.some((item) => item.id === albumInfo.id)) {
            dispatch({ type: "duplicate-error" });
            return setTimeout(() => {
              dispatch({ type: "none" });
            }, 3000);
          } else {
            setFavoriteAlbums((prev) => [...prev, albumInfo]);
            dispatch({ type: "add-fav" });
            setTimeout(() => {
              dispatch({ type: "none" });
            }, 3000);
          }
        }
        break;
      default:
        console.error("Error, no such case");
    }
  };

  const handleListClick = async (e: React.MouseEvent<HTMLElement>) => {
    //console.log(e.target, e.currentTarget.closest("li"), e.currentTarget);
    setIsFirstTime(false); // Used to set the play-pause icon to play in PlayBar.js
    // FIXME: code below will cause the currentPlaylist not working (because of  "song.url && !isFirstTime" above) when isFirstTime is true.
    // setTimeout(() => {
    //   setIsFirstTime(true); // If there is no interaction after 5 min, set isFirstTime to true in order to trigger fetchSong function and avoid 403 error.
    // }, 5* 60 * 1000);
    const { action } = e.currentTarget.dataset;

    //if (!(e.target instanceof HTMLElement)) return;

    const dataset = e.currentTarget.closest("li")?.dataset; // FIXME: Not sure how to avoid the could be null warning by ts. //2021-07-13 22:02:49 Got a workaround by using ts-ignore
    if (!dataset) return;
    const { id, index, name, artists, artistid, image, album, mvid } = dataset;
    console.log(
      "ouside",
      id,
      index,
      name,
      artists,
      artistid,
      image,
      album,
      mvid,
      typeof id
    );

    switch (action) {
      case "play-item":
        if (
          id &&
          index &&
          name &&
          artists &&
          artistid &&
          image &&
          album &&
          mvid
        ) {
          //const checkedIndex = currentList.findIndex((item) => item.id === +id);
          //if (checkedIndex !== -1) {
          console.log(id, index, name, artists, artistid, album, mvid);
          setClickedSong({
            id: +id,
            index: 0,
            name,
            artists,
            artistId: +artistid,
            image,
            album,
            mvId: +mvid,
          });
          //} else {
          // FIXME: currentList.length might cause issue if the setCurrentList (setState) runs not in sequence
          // setClickedSong({
          //   id: +id,
          //   index: currentList.length,
          //   name,
          //   artists,
          //   artistId: +artistid,
          //   image,
          //   album,
          //   mvId: +mvid,
          // });
          // FIXME: without checkMusicAvailability, the clicked song will be added to currentList even it's not playable
          // setCurrentList((prev) => [...prev, renderList[+index]]);
          //}

          // FIXME: The order of setCurrentList must be ahead of setSong, as the documentTitle function (within PlayBar.ts) relies on the currentList when song changes.
        }
        break;
      case "play-video":
        if (mvid && artistid) {
          history.push({
            pathname: `/video/${artistid}/${mvid}`,
            // state: { mvId: mvid, artistId: artistid },
          });
        }
        break;
      case "details-dot":
        //FIXME: a little trick below, because all the types are string, so it still can pass the check even it's "0",
        if (
          id &&
          index &&
          name &&
          artists &&
          artistid &&
          image &&
          album &&
          mvid
        ) {
          setSongActionInfo({
            id: +id,
            index: +index,
            name,
            artists,
            artistId: +artistid,
            image,
            album,
            mvId: +mvid,
          });
        }
        history.push({
          pathname: location.pathname,
          search: "?song-action",
          state: { background: location },
        });
        break;
      default:
        console.error("Error, no such case");
    }
  };

  const handleThrottleClick = (e: React.MouseEvent<HTMLElement>) => {
    console.log(e, e.target, e.currentTarget);

    //throttle(()=>handleClick(e), 150); // Not working, not sure why
    handleClick(e);
  };

  // if (songRef.current) {
  //   songRef.current.addEventListener("click", throttle(handleClick, 150));
  // }

  // console.log("dynamicColor is", dynamicColor);

  return (
    <Wrapper>
      <div
        className="header-container"
        style={{
          background: albumInfo?.color,
          display: albumInfo?.name ? "flex" : "none",
        }}
        data-action="open-description-screen"
        onClick={handleClick}
      >
        {albumInfo ? (
          <>
            <div className="image-container">
              <img
                src={albumInfo.cover + "?param=300y300"}
                alt={albumInfo.name}
              />
            </div>
            <div className="text-container">
              <div className="title">{albumInfo.name}</div>
              <div
                className="description"
                data-action="expand-description"
                onClick={handleClick}
              >
                {albumInfo.description}
              </div>
            </div>
          </>
        ) : null}
      </div>
      {albumInfo ? (
        <div
          className="control"
          style={{ marginTop: albumInfo?.name ? "-15px" : "0" }}
        >
          <div
            className="control-icon-text"
            data-action="play-all"
            onClick={handleClick}
          >
            <RiPlayCircleLine />

            <span>{t("music-list.play-all")} </span>
          </div>
          {["/favorite", "/history"].includes(location.pathname) ? (
            <div
              className="control-icon-text"
              data-action="delete-all"
              onClick={handleClick}
            >
              <AiOutlineDelete />
              <span>{t("music-list.delete-all")}</span>
            </div>
          ) : [...categoryList, "album"].includes(params.type) ? (
            favoriteAlbums.some((item) => item.id === albumInfo?.id) ? (
              <div
                style={{ color: "#ccc" }}
                className="control-icon-text"
                // data-action="fav-album"
                // onClick={handleClick}
              >
                <AiOutlineFolderAdd />
                <span>{t("music-list.added-fav-album")}</span>
              </div>
            ) : (
              <div
                style={{
                  color: "#000",
                }}
                className="control-icon-text"
                data-action="fav-album"
                onClick={handleClick}
              >
                <AiOutlineFolderAdd />
                <span>{t("music-list.fav-album")}</span>
              </div>
            )
          ) : null}
        </div>
      ) : null}
      <div ref={topElementRef}></div>
      <ul>
        {renderList.length ? (
          renderList.map((item, index) => (
            <li
              key={item.id}
              data-id={item.id}
              data-index={index}
              data-name={item.name}
              data-artists={item.ar.map((artist) => artist.name).join(" ")}
              data-artistid={item.ar[0].id}
              data-image={item.al.picUrl}
              data-album={item.al.name}
              data-mvid={item.mv}
            >
              <div
                className="song-info"
                data-action="play-item"
                // data-id={item.id}
                // data-index={index}
                // data-artistid={item.ar[0].id}
                // ref={songRef}
                onClick={handleListClick}
              >
                <div>
                  {clickedId === item.id ? (
                    <span className="current-indicator-icon">
                      <RiBarChartFill />
                    </span>
                  ) : (
                    index + 1
                  )}
                </div>
                <div>
                  <div className="song-title">{item.name}</div>
                  <div className="artist-name">
                    {item.ar.map((artist, index) => (
                      <span key={index}>{artist.name} </span>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className="play-video"
                data-action="play-video"
                data-mvid={item.mv}
                data-artistid={item.ar[0].id}
                onClick={handleListClick}
              >
                {item.mv ? <RiYoutubeLine /> : null}
              </div>
              <div
                className="dot-details"
                data-action="details-dot"
                onClick={handleListClick}
              >
                <BsThreeDotsVertical />
              </div>
            </li>
          ))
        ) : (
          // : !favoriteList.length ? (
          // <div className="no-record-indicator">
          //   <span>
          //     <BsInbox />
          //   </span>

          //   <p>Whoops, no record :(</p>
          // </div>
          // )
          <div className="loading-indicator">
            <span>
              <RiFlightTakeoffLine />
            </span>
            <p>{t("common.loading")}</p>
          </div>
        )}
      </ul>
    </Wrapper>
  );
}

export default MusicListDetails;
