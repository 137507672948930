import styled from "styled-components";
import { colors, loadingAnimation } from "../utils/styles/global.styles";

export const Wrapper = styled.div`
  /* display: flex; */
  /* flex-direction: column;
  justify-content: center;
  align-items: center; */
  /* width: 100%; */
  margin: 50px 0;
  height: calc(100vh - 105px);
  padding: 0 10px;
  background: ${colors.lightYellow};
  width: inherit;
  max-width: inherit;
  position: relative;

  .avatar {
    /* flex: 20%; */
    /* display: flex;
    justify-content: center;
    align-items: center; */

    text-align: center;
    padding: 30px;

    input {
      display: none;
    }

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
  .lang-switch {
    position: absolute;
    top: 50px;
    left: 0;
    padding: 20px;
    /* font-size: 20px; */
    cursor: pointer;

    span {
      padding: 1px 3px;
    }
  }
  .setting-icon {
    position: absolute;
    top: 50px;
    right: 0;
    padding: 20px;
    font-size: 20px;
    cursor: pointer;
  }

  .import-export {
    text-align: center;

    button,
    label {
      padding: 0 10px;
      margin: 0 10px;
      cursor: pointer;

      &:hover {
        background: ${colors.clickEffect};
        /* color: #fff; */
      }

      &:active {
        background: ${colors.clickEffect};
        transition: 0.1s;
      }
    }

    button {
      background: ${colors.lightBlue};
    }

    label {
      background: ${colors.lightOrange};
    }

    input {
      display: none;
    }
  }

  .album-title {
    font-weight: 600;
    font-size: 15px;
    padding: 15px 0;
  }

  .fav-container {
    display: flex;
    /* justify-content: space-between;  */
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 15px;
    max-height: 330px; */
    overflow-x: auto;
  }

  .fav-list,
  .fav-albums,
  .history-list {
    flex: 0 0 40%;
    max-width: 500px;

    .image-container {
      height: 150px;

      @media (min-width: 600px) {
        height: 200px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .fav-albums {
    padding-left: 15px;
    position: relative;
    .album-info-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #fff;
      font-size: 30px;
      background: #000000a1;
    }

    .delete-fav-album {
      position: absolute;
      top: 40px;
      right: 0px;
      width: 80px;
      height: 30px;
      background: #fff;
      color: #000;
      font-weight: 500;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .history-analytics-container {
    display: flex;

    .history-container,
    .analytics-container {
      flex: 45%;
      max-width: 500px;
    }

    .analytics-container {
      padding-left: 15px;
    }

    .analytics {
      height: 150px;
      background: #bdc7c9; // linear-gradient(45deg, #a7bbc7, #c9e4c5);
      padding: 5px;

      @media (min-width: 600px) {
        height: 200px;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        height: 100%;
        /* padding: 10px; */

        li {
          border-radius: 50%;
          /* display: flex;
          justify-content: center;
          align-items: center;
          background: lightgreen; */

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 30px;
            color: #fff;
            /* backdrop-filter: blur(2px); */

            /* background: #000000a1; */
          }
        }

        li:nth-child(1) {
          flex: 0 0 40%;
          height: 50%;
        }

        li:nth-child(2) {
          flex: 0 0 35%;
          height: 43%;
          align-self: flex-end;
        }

        li:nth-child(3) {
          flex: 0 0 25%;
          height: 30%;
        }
      }
    }
  }

  .no-record-indicator {
    flex: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-size: 50px;
    }
  }

  .sync-dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(0, 0, 0, 0.8);

    input {
      padding: 5px;
    }

    button {
      padding: 5px 15px;
      /* color: #fff; */
      background: lightblue;
      outline: none;
      /* border: none; */
    }
  }

  .close-icon {
    font-size: 25px;
    color: #ffffff9e;
    text-align: right;
    padding: 15px;
    position: absolute;
    top: 50px;
    right: 10px;
    cursor: pointer;
  }

  .pending {
    padding: 20px;
    font-size: 40px;
    color: #fff;
    position: relative;
    animation: ${loadingAnimation} 6s linear 0s infinite;
  }
`;
