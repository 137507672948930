import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 20;

  .close-icon {
    font-size: 25px;
    color: #ffffff9e;
    text-align: right;
    padding: 15px;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    max-height: 90vh;
    width: 100%;
    padding: 40px 10% 15px 10%;
    margin: 0 auto;
    overflow: auto;
  }

  .image-container {
    width: 100%;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text-container {
    color: #fff;
    //overflow: auto;

    /* padding: 20px; */

    .title {
      text-align: center;
      /* font-size: 18px; */
      padding: 20px 0;
    }

    .description {
      text-align: left;
      font-size: 13px;
      color: #eee;
      line-height: 2;

      overflow: auto;
    }
  }
`;
