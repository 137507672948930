import styled from "styled-components";
import { colors } from "../utils/styles/global.styles";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .buttons {
    width: 60%;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;

    .cancel {
      background: ${colors.lightGray};
      /* border: none; */
    }

    .delete {
      background: ${colors.lightRed};
    }
  }
`;
