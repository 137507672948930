import React from "react";
import ArtistInfo from "../components/ArtistInfo";

function Artist() {
  return (
    <>
      <ArtistInfo />
    </>
  );
}

export default Artist;
