import styled from "styled-components";
import { colors } from "../utils/styles/global.styles";

const width = 100;
const height = 100;
const length = 100;

export const Wrapper = styled.div`
  position: fixed;
  /* top: 0;
  left: 0; */
  height: 100vh;
  width: inherit;
  max-width: inherit;
  z-index: 100;
  background: #eee;
  overflow: auto;

  .header {
    display: flex;
    position: relative;
    color: #bbb;
    padding: 10px;
    font-size: 25px;
    justify-content: space-between;
  }

  .data-container {
    .title {
      text-align: center;
    }
    .color-picker-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .color-picker {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 50%;
        margin: 10px;
        text-align: center;
        vertical-align: middle;
        color: #fff;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* border: 1px #ddd solid; */
      }
    }

    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 10px;

      .album-cover {
        height: 60px;
        //width: 60px;
        margin-right: 20px;
        flex: 0 0 60px;
        /* border-radius: 50%; */

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .song-info {
        flex: 0 0 70%;

        .song-name {
          //font-weight: 500;
          font-size: 14px;
        }

        .song-artist {
          font-size: 12px;
        }
      }

      .play-count {
        flex: 0 0 10%;
        font-size: 30px;
        font-weight: 500;
        text-align: center;
      }
    }
  }
`;

export const Cube = styled.div`
  width: 100%;
  height: ${height + 100}px;
  display: flex;
  justify-content: center;
  align-items: center;

  .td-cube {
    width: ${width}px;
    height: ${height}px;
    perspective: 1000px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;
      width: 100%;
      height: 100%;
      transform: rotateX(-15deg) rotateY(15deg);
      transform-style: preserve-3d;
      animation: rotate 6s infinite linear;
    }
    li {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      width: ${width}px;
      height: ${height}px;
      opacity: 0.8;
      font-size: 30px;
      color: #fff;
      &.front {
        background-color: #f66;
        transform: translateZ(${length / 2}px);
      }
      &.back {
        background-color: #66f;
        transform: rotateY(180deg) translateZ(${length / 2}px);
      }
      &.top {
        background-color: #f90;
        transform: rotateX(90deg) translateZ(${height / 2}px);
      }
      &.bottom {
        background-color: #09f;
        transform: rotateX(-90deg) translateZ(${height / 2}px);
      }
      &.left {
        background-color: #9c3;
        transform: rotateY(-90deg) translateZ(${width / 2}px);
      }
      &.right {
        background-color: #3c9;
        transform: rotateY(90deg) translateZ(${width / 2}px);
      }
    }
  }
  @keyframes rotate {
    from {
      transform: rotateY(0) rotateX(0);
    }
    to {
      transform: rotateY(-1turn) rotateX(-1turn);
    }
  }
`;
