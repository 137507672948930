import styled from "styled-components";
import { colors } from "../utils/styles/global.styles";

export const Wrapper = styled.div<{ albumImage: string }>`
  /* display: block;
  position: fixed;
  bottom: 55px; // The height of play control plus the height of progress bar
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10; */
  width: inherit;
  max-width: inherit;

  .profile-modal-content {
    /* display: block;
    position: fixed;
    top: 50px; // The height search bar */
    /* bottom: 55px; */
    /* width: 100%;
    height: calc(
      100vh - 105px
    );  */
    // 100vh minus the height of search bar 50px and the height of paly bar 55px
    /* background: linear-gradient(
      to bottom right,
      rgb(255, 216, 191),
      rgb(255, 216, 191, 0.9),
      rgb(255, 216, 191, 0.8)
    ); */
    // background: #fbf2e3;;
  }

  .play-panel-modal-wrapper {
    // position: fixed;
    // top: 50px; // The height search bar */
    /* top: 0;
    bottom: 55px;
    width: 100%; */
    /* height: 100%; */
    //calc(100vh - 105px); // 100vh minus the height of search bar 50px and the height of paly bar 55px
    /* background: ${({ albumImage }) =>
      `linear-gradient(rgba(100, 100, 100, 0.6), rgba(100, 100, 100, 0.6)),
  url(${albumImage})`};
    background-size: cover;
    background-attachment: fixed;
    overflow: auto; */
  }

  .current-playlist-modal-wrapper {
    position: fixed;
    bottom: 0; // The height of play control plus the height of progress bar
    /* width: 100%; */
    width: inherit;
    max-width: inherit;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;

    .current-playlist-modal-content {
      position: fixed;
      bottom: 2px; // The height of play control plus the height of progress bar
      /* width: 100%; */
      width: inherit;
      max-width: inherit;
      height: 60vh;
      background: ${colors.lightYellow};
      border-radius: 30px 30px 0 0;
      overflow: auto;
    }
  }

  .song-action-modal-wrapper {
    position: fixed;
    bottom: 0; // The height of play control plus the height of progress bar
    width: inherit;
    max-width: inherit;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;

    .song-action-modal-content {
      position: fixed;
      bottom: 2px; // The height of play control plus the height of progress bar
      width: inherit;
      max-width: inherit;
      max-height: 60%;
      background: ${colors.lightYellow};
      border-radius: 30px 30px 0 0;
      /* overflow: auto; */
    }
  }

  .delete-all-modal-wrapper {
    position: fixed;
    bottom: 55px; // The height of play control plus the height of progress bar
    width: inherit;
    max-width: inherit;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    .delete-all-modal-content {
      position: fixed;
      // bottom: 40vh;

      width: inherit;
      max-width: 400px;
      height: 140px;
      background: #ff9d72; //${colors.lightBlue};
      border-radius: 30px;
    }
  }
`;
