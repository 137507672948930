import React, {
  useState,
  createContext,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { getArtistTopPlaylist } from "../api/artist";
import { getPlaylistDetails } from "../api/playlist";
import { getTrackDetails } from "../api/track";
import { api } from "../services/api";

interface Props {
  children: ReactNode;
}

interface AnalyticData {
  id: number;
  name: string;
  artists: string;
  artistId: number;
  image: string;
  album: string;
  mvId: number;
  playCount: number;
  date: string[];
}

interface Song {
  id: number;
  index: number;
  url: any;
  name: string;
  artists: string;
  artistId: number;
  image: string;
  album: string;
  mvId: number;
}

export interface PlayListItem {
  id: number;
  name: string;
  ar: {
    [key: string]: string;
  }[];
  al: {
    [key: string]: string;
  };
  mv: number;
  // artists: {
  //   [key: string]: string;
  // }[];
  // album: {
  //   [key: string]: string;
  // };
}

export interface AlbumInfo {
  id: number;
  name: string;
  description: string;
  cover: string;
  color?: string;
}

interface ClickedSongInfo {
  id: number;
  index: number;
  name: string;
  artists: string;
  artistId: number;
  image: string;
  album: string;
  mvId: number;
}

type SongContextType = {
  song: Song;
  setSong: (song: Song) => void;
  playList: PlayListItem[];
  setPlayList: (playList: PlayListItem[]) => void;
  renderList: PlayListItem[];
  setRenderList: (playList: PlayListItem[]) => void;
  currentList: PlayListItem[];
  setCurrentList: (
    currentList:
      | PlayListItem[]
      | ((prevState: PlayListItem[]) => PlayListItem[])
  ) => void;
  clickedId: number;
  setClickedId: (clickedId: number) => void;
  isFirstTime: boolean;
  setIsFirstTime: (isFirstTime: boolean) => void;
  lyric: string;
  setLyric: (lyric: string) => void;
  songActionInfo: ClickedSongInfo;
  setSongActionInfo: (songActionInfo: ClickedSongInfo) => void;
  favoriteList: PlayListItem[];
  setFavoriteList: (
    favoriteList:
      | PlayListItem[]
      | ((prevState: PlayListItem[]) => PlayListItem[])
  ) => void;
  favoriteAlbums: AlbumInfo[];
  setFavoriteAlbums: (
    favoriteAlbums: AlbumInfo[] | ((prevState: AlbumInfo[]) => AlbumInfo[])
  ) => void;
  historyList: PlayListItem[];
  setHistoryList: (
    historyList:
      | PlayListItem[]
      | ((prevState: PlayListItem[]) => PlayListItem[])
  ) => void;
  playListId: number;
  setPlayListId: (playListId: number) => void;
  playCurrentTime: any; //number;
  setPlayCurrentTime: (playCurrentTime: number) => void;
  isDelete: boolean;
  setIsDelete: (isDelete: boolean) => void;
  clickedSong: ClickedSongInfo;
  setClickedSong: (
    clickedSong:
      | ClickedSongInfo
      | ((prevState: ClickedSongInfo) => ClickedSongInfo)
  ) => void;
  isMusicPlaying: boolean;
  setIsMusicPlaying: (
    isMusicPlaying: boolean | ((prev: boolean) => boolean)
  ) => void;
  albumInfo: AlbumInfo | undefined;
  setAlbumInfo: (albumInfo: AlbumInfo | undefined) => void;
  analyticData: AnalyticData[];
  setAnalyticData: (
    analyticData:
      | AnalyticData[]
      | ((prevState: AnalyticData[]) => AnalyticData[])
  ) => void;
};

export const SongContext = createContext<SongContextType | null>(null);

const initialClickedSongInfo = {
  id: 0,
  index: 0,
  name: "",
  artists: "",
  artistId: 0,
  image: "",
  album: "",
  mvId: 0,
};

export function SongProvider({ children }: Props) {
  const localCurrentList = localStorage.getItem("currentList");
  const localPlayList = localStorage.getItem("playList");
  const localFavoriteList = localStorage.getItem("favoriteList");
  const localFavoriteAlbums = localStorage.getItem("favoriteAlbums");
  const localHistoryList = localStorage.getItem("historyList");
  const localSong = localStorage.getItem("song");
  const localAnalyticData = localStorage.getItem("analyticData");
  // console.log("localPlayList is triggered", localPlayList);

  const [song, setSong] = useState(
    localSong ? JSON.parse(localSong) : { id: 0, index: 0, url: "" }
  );
  const [playList, setPlayList] = useState<PlayListItem[]>(
    localPlayList && localPlayList !== "undefined"
      ? JSON.parse(localPlayList)
      : []
  );
  const [currentList, setCurrentList] = useState<PlayListItem[]>(
    localCurrentList ? JSON.parse(localCurrentList) : []
  );
  const [clickedId, setClickedId] = useState(0); // Used for current playing icon
  const [isFirstTime, setIsFirstTime] = useState(true); // Used for playing and fetching song's url within PlayBar.js
  const [lyric, setLyric] = useState("");
  const [songActionInfo, setSongActionInfo] = useState(initialClickedSongInfo);
  const [favoriteList, setFavoriteList] = useState<PlayListItem[]>(
    localFavoriteList ? JSON.parse(localFavoriteList) : []
  );

  const [favoriteAlbums, setFavoriteAlbums] = useState<AlbumInfo[]>(
    localFavoriteAlbums ? JSON.parse(localFavoriteAlbums) : []
  );

  const [historyList, setHistoryList] = useState<PlayListItem[]>(
    localHistoryList ? JSON.parse(localHistoryList) : []
  );

  const [analyticData, setAnalyticData] = useState<AnalyticData[]>(
    localAnalyticData ? JSON.parse(localAnalyticData) : []
  );

  const [playListId, setPlayListId] = useState(0);
  const [playCurrentTime, setPlayCurrentTime] = useState(0);
  //const playCurrentTime = useRef(null);
  const [isDelete, setIsDelete] = useState(false);
  const [clickedSong, setClickedSong] = useState(initialClickedSongInfo);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [albumInfo, setAlbumInfo] = useState<AlbumInfo>();
  const [renderList, setRenderList] = useState<PlayListItem[]>([]);

  localStorage.setItem("currentList", JSON.stringify(currentList));
  localStorage.setItem("playList", JSON.stringify(playList));
  localStorage.setItem("favoriteList", JSON.stringify(favoriteList));
  localStorage.setItem("favoriteAlbums", JSON.stringify(favoriteAlbums));
  localStorage.setItem("historyList", JSON.stringify(historyList));
  localStorage.setItem("song", JSON.stringify(song));
  localStorage.setItem("analyticData", JSON.stringify(analyticData));

  // const location: LocationType = useLocation();
  // const id = location.state && location.state.playlistId; //: 3197267;

  return (
    <SongContext.Provider
      value={{
        albumInfo,
        setAlbumInfo,
        song,
        setSong,
        playList,
        setPlayList,
        renderList,
        setRenderList,
        currentList,
        setCurrentList,
        clickedId,
        setClickedId,
        isFirstTime,
        setIsFirstTime,
        lyric,
        setLyric,
        songActionInfo,
        setSongActionInfo,
        favoriteList,
        setFavoriteList,
        favoriteAlbums,
        setFavoriteAlbums,
        playListId,
        setPlayListId,
        historyList,
        setHistoryList,
        playCurrentTime,
        setPlayCurrentTime,
        isDelete,
        setIsDelete,
        clickedSong,
        setClickedSong,
        isMusicPlaying,
        setIsMusicPlaying,
        analyticData,
        setAnalyticData,
      }}
    >
      {children}
    </SongContext.Provider>
  );
}

// export const useSong = () => useContext(SongContext);
