import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  forwardRef,
  ChangeEvent,
} from "react";
import { useTranslation } from "react-i18next";
import {
  RiHeavyShowersLine,
  RiDrizzleLine,
  RiMessage2Line,
  RiHeartLine,
  RiHeartFill,
  RiContactsLine,
  RiAccountBoxLine,
  RiAccountCircleLine,
  RiArrowDownSLine,
  RiYoutubeLine,
} from "react-icons/ri";
import {
  AiFillStepBackward,
  AiFillStepForward,
  AiFillCaretRight,
  AiOutlinePause,
  AiOutlineMore,
  AiOutlineAlignLeft,
  AiOutlineAlignCenter,
  AiOutlineIdcard,
  AiOutlineFire,
  AiOutlineProfile,
  AiOutlinePicLeft,
  AiOutlineShareAlt,
} from "react-icons/ai";
import { BsMusicNoteList, BsShuffle } from "react-icons/bs";
import { playModeIcons } from "../assets/icons/playModeIcons";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getTrackDetails } from "../api/track";
import { MessageContext } from "../context/MessageProvider";
import { SongContext } from "../context/SongProvider";
import { api } from "../services/api";
import {
  LyricList,
  Lyric,
  Wrapper,
  ControlContainer,
} from "./MusicPlayPanel.styles";
import { formatMusicPlayTimeAsMins } from "../utils/formatter";
import { PlayerContext } from "../context/PlayerProvider";
import { randomColor } from "../utils/generator";
import { colors } from "../utils/styles/global.styles";
import { useDelay } from "../hooks/useDelay";

// const lyric =
//   "[00:04.050]\n[00:12.570]难以忘记初次见你\n[00:16.860]一双迷人的眼睛\n[00:21.460]在我脑海里\n[00:23.960]你的身影 挥散不去\n[00:30.160]握你的双手感觉你的温柔\n[00:34.940]真的有点透不过气\n[00:39.680]你的天真 我想珍惜\n[00:43.880]看到你受委屈 我会伤心\n[00:48.180]喔\n[00:50.340]只怕我自己会爱上你\n[00:55.070]不敢让自己靠的太近\n[00:59.550]怕我没什么能够给你\n[01:04.030]爱你也需要很大的勇气\n[01:08.190]只怕我自己会爱上你\n[01:12.910]也许有天会情不自禁\n[01:17.380]想念只让自己苦了自己\n[01:21.840]爱上你是我情非得已\n[01:28.810]难以忘记初次见你\n[01:33.170]一双迷人的眼睛\n[01:37.700]在我脑海里 你的身影 挥散不去\n[01:46.360]握你的双手感觉你的温柔\n[01:51.120]真的有点透不过气\n[01:55.910]你的天真 我想珍惜\n[02:00.150]看到你受委屈 我会伤心\n[02:04.490]喔\n[02:06.540]只怕我自己会爱上你\n[02:11.240]不敢让自己靠的太近\n[02:15.750]怕我没什么能够给你\n[02:20.200]爱你也需要很大的勇气\n[02:24.570]只怕我自己会爱上你\n[02:29.230]也许有天会情不自禁\n[02:33.680]想念只让自己苦了自己\n[02:38.140]爱上你是我情非得已\n[03:04.060]什么原因 耶\n[03:07.730]我竟然又会遇见你\n[03:13.020]我真的真的不愿意\n[03:16.630]就这样陷入爱的陷阱\n[03:20.700]喔\n[03:22.910]只怕我自己会爱上你\n[03:27.570]不敢让自己靠的太近\n[03:32.040]怕我没什么能够给你\n[03:36.560]爱你也需要很大的勇气\n[03:40.740]只怕我自己会爱上你\n[03:45.460]也许有天会情不自禁\n[03:49.990]想念只让自己苦了自己\n[03:54.510]爱上你是我情非得已\n[03:58.970]爱上你是我情非得已\n[04:03.000]\n";

interface LyricInfo {
  timestamp: number | undefined;
  lyric: string;
  index: number;
}

interface Props {
  playModeIndex: number;
  setPlayModeIndex: (
    playModeIndex: number | ((prevState: number) => number)
  ) => void;
  isMusicPlaying: boolean;
  setIsMusicPlaying: (
    isMusicPlaying: boolean | ((preState: boolean) => boolean)
  ) => void;
  playCurrentTime: number;
  setPlayCurrentTime: (playCurrentTime: number) => void;
  progressValue: number;
  setProgressValue: (progressValue: number) => void;
  lyric: string;
  setIsPlayPanelShowing: any;
  handleNextSong: any;
  handleShuffle: any;
  handlePreviousSong: any;
  playerRef: any;
}

function MusicPlayPanel() {
  const { t } = useTranslation();
  const [lyricInfo, setLyricInfo] = useState<LyricInfo[]>([]);
  // const [currentLyricIndex, setCurrentLyricIndex] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ id: string }>();
  const songId = params.id;
  //const progressRef = useRef<HTMLProgressElement>(null);

  const songContext = useContext(SongContext);
  const messageContext = useContext(MessageContext);
  const playerContext = useContext(PlayerContext);

  if (!songContext || !messageContext || !playerContext) throw new Error();
  const {
    song,
    setSong,
    setClickedSong,
    setIsFirstTime,
    //lyric,
    //playCurrentTime,
    favoriteList,
    setFavoriteList,
    //isMusicPlaying,
    currentList,
    setSongActionInfo,
  } = songContext;

  const { dispatch } = messageContext;

  const {
    lyric,
    setLyric,
    isMusicPlaying,
    setIsMusicPlaying,
    playCurrentTime,
    setPlayCurrentTime,
    playModeIndex,
    setPlayModeIndex,
    progressValue,
    setProgressValue,
    isPlayPanelShowing,
    setIsPlayPanelShowing,
    clickedPlayerControlOption,
    setClickedPlayerControlOption,
    currentLyricIndex,
    setCurrentLyricIndex,
    // handleShuffle,
    // handlePreviousSong,
    // handleNextSong,
    playerRef,
  } = playerContext;

  const lyricContainerRef = useRef<HTMLDivElement>(null);

  const { isDelayOver } = useDelay(5000);

  const { status, data: songInfo, error } = useQuery(
    "shared-song",
    () => getTrackDetails(songId),
    {
      enabled: !!songId,
    }
  );

  useEffect(() => {
    console.log(songInfo);

    if (songInfo) {
      // setIsFirstTime(false); // To trigger play-pause icon //2021-07-17 19:22:08 FIXME: seems most browser not allow autoplay, still needs to click the play-pause icon to make it play
      const data = songInfo.data.songs[0];

      // The default value of songInfo is undefined, but the returned songInfo.data.songs[0] could also be undefined when the id from params is not valid
      if (data) {
        setClickedSong({
          id: data.id,
          index: 0,
          name: data.name,
          artists: data.ar
            .map((artist: { name: any }) => artist.name)
            .join(" "),
          artistId: data.ar[0].id,
          image: data.al.picUrl,
          album: data.al.name,
          mvId: data.mv,
        });
      } else {
        // console.error("The song with this id cannot be played");
        dispatch({ type: "play-error" });
        setTimeout(() => {
          dispatch({ type: "none" });
        }, 3000);
      }
    }
  }, [songInfo]);

  //console.log("isMusicPlaying ", isMusicPlaying);

  useEffect(() => {
    // Scroll the lyrics container to top when lyrics (song) changes
    if (lyricContainerRef.current) {
      lyricContainerRef.current.scrollTo(0, 0);
    }

    const pattern = /\[\d{2}:\d{2}\.\d{2,3}\]/;
    const lyricArray = lyric.split("\n").map((item, index) => ({
      timestamp: item
        .match(pattern)?.[0] //Optional chaining
        .slice(1, -1)
        .split(":")
        .reduce((total, time) => Number(total) * 60 + Number(time), 0),
      lyric: item.replace(pattern, ""),
      index,
    })); // FIXME: Fixed, because of the g tag added at the end of pattern. but not sure why//Not sure why the regex pattern not working well as there are some false value from this expression
    // console.log(lyricArray);
    setLyricInfo(lyricArray);
  }, [lyric]);

  useEffect(() => {
    if (lyricInfo.length && lyricInfo[0].lyric) {
      const lyricIndex = lyricInfo
        .filter((item) => item.timestamp && item.timestamp <= playCurrentTime)
        .reverse()[0]?.index;
      // console.log(lyricIndex);
      setCurrentLyricIndex(lyricIndex);
    }
  }, [playCurrentTime]);

  useEffect(() => {
    //console.log("scroll is triggered");
    //window.scrollBy({ top: 10, behavior: "smooth" }); // FIXME: Not sure why scrollBy and scrollTo is not working here?
    // setTimeout(() => {
    //   window.scrollTo({ top: 0, behavior: "smooth" });
    // }, 200);
    //console.log(currentLyricIndex);
    if (lyricContainerRef.current && currentLyricIndex > 9) {
      lyricContainerRef.current.scrollTo(0, (currentLyricIndex - 7) * 30);
      //lyricContainerRef.current.scrollBy({ top: 30, behavior: "smooth" });
    }
  }, [currentLyricIndex]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const { action } = e.currentTarget.dataset;
    switch (action) {
      // case "page":
      //   return history.goBack();
      case "backward":
        //FIXME: A workaround for share song, in that case, going to home page when clicking backward icon
        // 2022-02-25 18:04:12 Fixed by using document.referrer https://developer.mozilla.org/en-US/docs/Web/API/Document/referrer
        //2022-04-15 11:55:14 document.referrer has some issue with local router, now using session storage to handle prev and current path changes

        // console.log("document.referrer", document.referrer);

        // if (document.referrer) {
        //   history.goBack();
        // } else {
        //   history.push("/");
        // }
        //setIsPlayPanelShowing(false);
        const storage = globalThis?.sessionStorage;
        if (storage.prevPath) {
          history.goBack();
        } else {
          history.push("/");
        }
        break;
      case "three-dot":
        setSongActionInfo({
          id: song.id,
          index: song.index,
          name: song.name,
          artists: song.artists,
          artistId: song.artistId,
          image: song.image,
          album: song.album,
          mvId: song.mvId,
        });
        history.push({
          pathname: location.pathname,
          search: "?song-action",
          state: { background: location },
        });
        break;
      case "comment":
        history.push({
          pathname: `/comments/${song.id}`,
          // state: { songId: song.id },
        });
        break;
      case "share":
        if (navigator.share && song.id) {
          navigator
            .share({
              title: song.name,
              text: `${song.name}-${song.artists} ${window.location.hostname}/panel/${song.id}`,
            })
            .then()
            .catch((error) => console.error(error));
        } else {
          dispatch({ type: "share-error" });
          return setTimeout(() => {
            dispatch({ type: "none" });
          }, 3000);
        }
        break;
      case "favorite":
        // If the song already existed in the fav list, then remove it
        if (
          favoriteList &&
          favoriteList.findIndex((item) => item.id === song.id) !== -1
        ) {
          setFavoriteList((prev) => prev.filter((item) => item.id !== song.id));
        } else {
          const currentSong = currentList.filter(
            (item) => item.id === song.id
          )[0];
          setFavoriteList((prev) => [...prev, currentSong]);
        }

        // Vibration effect
        if ("vibrate" in navigator) {
          navigator.vibrate(100);
        }

        break;
      case "artist":
        history.push({
          pathname: "/artist",
          state: { artistId: song.artistId },
        });
        break;
      case "mv":
        //setIsMusicPlaying(false);
        // console.log(song);
        history.push({
          pathname: `/video/${song.artistId}/${song.mvId}`,
          // state: { mvId: song.mvId, artistId: song.artistId },
        });
        break;
      case "play-mode":
        setPlayModeIndex((prev) => (prev < 2 ? prev + 1 : 0));
        break;
      case "previous-song":
        // if (playModeIndex === 1) return handleShuffle();
        // handlePreviousSong();
        setClickedPlayerControlOption({
          name: "previous",
          timestamp: Date.now(),
        });
        break;
      case "play-pause":
        setIsMusicPlaying((prev) => !prev);

        break;
      case "next-song":
        //if (playModeIndex === 1) return handleShuffle(); // If the user choose shuffle mode, next song should in shuffle mode
        //handleNextSong();
        setClickedPlayerControlOption({ name: "next", timestamp: Date.now() });
        break;
      case "current-playlist":
        if (location.search === "?current-playlist") {
          history.goBack(); // If clicking the same icon the second time, close the modal
        } else {
          history.push({
            pathname: location.pathname,
            search: "?current-playlist",
            state: { background: location },
          });
        }

        break;
      case "progress-bar":
        const element = e.currentTarget as HTMLInputElement;
        console.log("element.value", element.value);

        setProgressValue(+element.value);
        // // console.log(e.nativeEvent.offsetX); // Not sure why e.offsetX not working
        // const percent = e.nativeEvent.offsetX / e.currentTarget.offsetWidth;
        // // Add song.url to avoid NaN issue for playerRef.current.duration which could crash the app
        // if (song.url && playerRef?.current) {
        //   console.log(
        //     "within play panel,duration is",
        //     playerRef.current.currentTime,
        //     playerRef.current.duration
        //   );
        //   playerRef.current.currentTime = percent * playerRef.current.duration;
        //   setPlayCurrentTime(playerRef.current.currentTime);
        //   setProgressValue(
        //     playerRef.current.currentTime / playerRef.current.duration
        //   );
        // }
        break;
      default:
        console.error("Error, no such case");
    }
  };

  const handleProgressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //console.log("e.currentTarget.value", e.currentTarget.value);
    setProgressValue(+e.currentTarget.value);

    //Add song.url to avoid NaN issue for playerRef.current.duration which could crash the app
    if (song.url && playerRef?.current) {
      // console.log(
      //   "within play panel,duration is",
      //   playerRef.current.currentTime,
      //   playerRef.current.duration
      // );
      playerRef.current.currentTime =
        +e.currentTarget.value * playerRef.current.duration;
      setPlayCurrentTime(playerRef.current.currentTime);
    }
  };

  return (
    <Wrapper
      albumImage={song.image}
      // data-action="page"
      // onClick={handleClick}
    >
      <div className="container">
        <div className="header">
          <div
            className="back-icon"
            data-action="backward"
            onClick={handleClick}
          >
            <RiArrowDownSLine />
          </div>
          <div className="title">
            {song.name}-{song.artists}
          </div>
          <div
            className="three-dot"
            data-action="three-dot"
            onClick={handleClick}
          >
            <AiOutlineMore />
          </div>
        </div>

        <div className="lyrics-container" ref={lyricContainerRef}>
          <LyricList
          // style={{ transform: `translateY(${-currentLyricIndex * 30}px)` }}
          >
            {/* <LyricList> */}
            {lyricInfo.length && lyricInfo[0].lyric ? (
              lyricInfo.map((item, index) => (
                <Lyric key={index} isHighlight={index === currentLyricIndex}>
                  {item.lyric}
                </Lyric>
              ))
            ) : (
              <div className="loading-indicator">
                {!lyric ? (
                  <>
                    <p style={{ fontSize: "16px" }}>{t("common.loading")}</p>

                    {isDelayOver && (
                      <p style={{ fontSize: "12px" }}>
                        {t("common.not-auto-play")}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <span>
                      <RiDrizzleLine />
                    </span>
                    <p>{t("common.no-lyric")} </p>
                  </>
                )}
              </div>
            )}
          </LyricList>
        </div>

        <ControlContainer>
          {song.name && (
            <div className="icons">
              <div data-action="share" onClick={handleClick}>
                <AiOutlineShareAlt />
              </div>
              <div
                className="artist-icon"
                data-action="artist"
                onClick={handleClick}
              >
                <RiContactsLine />
              </div>

              <div
                className="favorite-icon"
                data-action="favorite"
                onClick={handleClick}
              >
                {favoriteList.some((item) => item.id === song.id) ? (
                  <span className="active">
                    <RiHeartFill />
                  </span>
                ) : (
                  <span>
                    <RiHeartLine />
                  </span>
                )}
              </div>
              {song.mvId ? (
                <div className="mv-icon" data-action="mv" onClick={handleClick}>
                  <RiYoutubeLine />
                </div>
              ) : null}
              <div
                className="comment-icon"
                data-action="comment"
                onClick={handleClick}
              >
                <RiMessage2Line />
              </div>
            </div>
          )}

          <div className="music-progress">
            {/* <progress
              // ref={progressRef}
              data-action="progress-bar"
              onClick={handleClick}
              value={progressValue}
              max="1"
            ></progress> */}
            <input
              type="range"
              data-action="progress-bar"
              onChange={handleProgressChange}
              // onClick={handleClick}
              value={progressValue}
              min="0"
              max="1"
              step="0.001"
              style={{
                backgroundImage: `linear-gradient(
        to right,
        ${colors.green} ${progressValue * 100}%,
        #ccc ${progressValue * 100}%,
        #ccc 100%
      )`,
              }}
            />
            <div className="music-duration">
              <span>
                {formatMusicPlayTimeAsMins(playerRef?.current?.currentTime)}
              </span>
              <span>
                {playerRef?.current?.duration
                  ? formatMusicPlayTimeAsMins(playerRef?.current?.duration)
                  : "00:00"}
              </span>
            </div>
          </div>
          <div className="music-control">
            <div
              className="play-mode"
              data-action="play-mode"
              onClick={handleClick}
            >
              {playModeIcons[playModeIndex]}
            </div>
            <div
              className="previous"
              data-action="previous-song"
              onClick={handleClick}
            >
              <AiFillStepBackward />
            </div>
            <div className="play-control">
              <div
                className="play-pause"
                data-action="play-pause"
                onClick={handleClick}
              >
                {isMusicPlaying ? <AiOutlinePause /> : <AiFillCaretRight />}
              </div>
            </div>
            <div className="next" data-action="next-song" onClick={handleClick}>
              <AiFillStepForward />
            </div>

            <div
              className="play-list"
              data-action="current-playlist"
              onClick={handleClick}
            >
              <BsMusicNoteList />
            </div>
          </div>
        </ControlContainer>
      </div>
    </Wrapper>
  );
}

export default MusicPlayPanel;
