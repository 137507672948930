import React from "react";
import MainContainer from "../components/layouts/MainContainer";
import MusicListDetails from "../components/MusicListDetails";
import SearchResults from "../components/SearchResults";

function SearchResult() {
  return (
    <MainContainer>
      <SearchResults />
    </MainContainer>
  );
}

export default SearchResult;
