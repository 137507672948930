import { api } from "../services/api";

interface ArtistListParams {
  type: number;
  //area: number;
  initial: number;
  limit: number;
  offset?: number;
  area?: string;
}

interface ArtistPlayListParams {
  id: number;
  type?: number;
  order?: string;
  limit?: number;
  offset?: number;
}

export const getArtistIdentity = (id: number) => {
  return api({
    method: "get",
    url: "/artist/detail",
    params: {
      id,
    },
  });
};

export const getArtistDesc = (id: number) => {
  return api({
    method: "get",
    url: "/artist/desc",
    params: {
      id,
    },
  });
};

// A list with different artists
// type 取值: -1:全部, 1:男歌手, 2:女歌手, 3:乐队
export const getArtistList = (params: ArtistListParams) => {
  return api({
    method: "get",
    url: "/artist/list",
    params,
  });
};

// A playlist with top songs of a specific artist
export const getArtistTopPlaylist = (id: number) => {
  return api({
    method: "get",
    url: "/artist/top/song",
    params: {
      id,
    },
  });
};

// A playlist with all songs of a specific artist
export const getArtistAllPlaylist = (params: ArtistPlayListParams) => {
  return api({
    method: "get",
    url: "/artist/songs",
    params,
  });
};
