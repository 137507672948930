import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { RiRunLine } from "react-icons/ri";
import { Wrapper, List } from "./MoreAlbums.styles";
import { throttle } from "../utils/throttleAndDebunce";
import { SongContext } from "../context/SongProvider";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
  getRecommendedPlaylist,
  getTopPlaylist,
  getTrendPlaylist,
} from "../api/playlist";
import { getArtistList } from "../api/artist";
import { albumsEndPoints } from "./MusicLists";
import { isNormalError } from "../utils/validation";
import { getAllVideos } from "../api/video";
import { Span } from "@sentry/tracing";
import { colors } from "../utils/styles/global.styles";
import { AlbumContext } from "../context/AlbumProvider";

const MAX_ALBUMS_COUNT = 50;

const filtersDict = {
  hot: {
    filters: [
      "全部",
      "流行",
      "民谣",
      "摇滚",
      "说唱",
      "古风",
      "华语",
      "欧美",
      "日语",
      "韩语",
    ],
  },
  artists: {
    filters: ["全部", "华语", "欧美", "日本", "韩国", "其他"],
    filtersDict: {
      全部: "-1",
      华语: "7",
      欧美: "96",
      日本: "8",
      韩国: "16",
      其他: "0",
    },
  },
  mv: {
    filters: ["全部", "内地", "港台", "欧美", "日本", "韩国"],
    orders: ["最热", "最新", "上升最快"],
    types: ["全部", "官方", "原生", "现场", "网易"],
  },
};

const fetchMoreAlbums = {
  trends: () => getTrendPlaylist(),
  hot: (offset: number = 0, filter: string = "全部") =>
    getTopPlaylist({
      order: "hot",
      limit: 18,
      offset: offset * 18,
      cat: filter,
    }),
  recommended: () => getRecommendedPlaylist({ limit: MAX_ALBUMS_COUNT - 2 }),
  artists: (offset: number = 1, filter: string = "-1") =>
    getArtistList({
      type: -1,
      initial: -1,
      limit: 18,
      offset: offset * 18,
      area: filter,
    }),
  mv: (
    offset: number = 0,
    filter: string = "全部",
    order: string = "最热",
    type: string = "全部"
  ) =>
    getAllVideos({
      limit: 18,
      offset: offset * 18,
      area: filter,
      order,
      type,
    }),
};

function MoreAlbums() {
  const { t } = useTranslation();
  const params = useParams<{ type: string }>();
  const history = useHistory();
  //const data: { state: { category: string } } = useLocation();
  const [offset, setOffset] = useState(0);
  const [moreAlbums, setMoreAlbums] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const songContext = useContext(SongContext);
  const albumContext = useContext(AlbumContext);
  if (!albumContext || !songContext) throw new Error();
  const { setPlayListId, setPlayList, setAlbumInfo } = songContext;
  const {
    selectedFilter,
    setSelectedFilter,
    selectedOrder,
    setSelectedOrder,
    selectedType,
    setSelectedType,
  } = albumContext;

  useEffect(() => {
    switch (params.type) {
      case "hot":
        if (!filtersDict.hot.filters.includes(selectedFilter)) {
          setSelectedFilter("全部");
        }
        break;
      case "artists":
        if (
          !Object.values(filtersDict.artists.filtersDict).includes(
            selectedFilter
          )
        ) {
          setSelectedFilter("-1");
        }
        break;
      case "mv":
        if (!filtersDict.mv.filters.includes(selectedFilter)) {
          setSelectedFilter("全部");
        }
        break;
      default:
      // console.error(
      //   "Error: params.type is not defined or no filter is needed"
      // );
    }
  }, []);

  const { isLoading, isError, isSuccess, error, data } = useQuery(
    ["moreAlbums", offset, selectedFilter, selectedOrder, selectedType],
    () =>
      fetchMoreAlbums[params.type](
        offset,
        selectedFilter,
        selectedOrder,
        selectedType
      ),
    {
      onSuccess: (data) => {
        if (data?.data.code === 200) {
          setMoreAlbums((prev) => [
            ...prev,
            ...data?.data[albumsEndPoints[params.type]],
          ]);
        }
        setHasMore(!!data.data.more || !!data.data.hasMore);
      },

      onError: (err) => {
        console.log(err);
      },
    }
  );

  //console.log("moreAlbums", moreAlbums);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  // FIXME: If scroll at the high speed, the offset value cannot update synchronously, and thus result in the duplicate keys error
  // FIXED: 2021-03-13, fixing the scrolling in fast rate issue by using intersectionObserver

  const bottomRef = useCallback((node) => {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        console.log("observer is triggered", entries[0].isIntersecting);
        if (params && entries[0].isIntersecting) {
          // FIXME: 2021-07-12 19:09:40 try to do the fetch within rather than relying on dispatch
          // 2021-12-26 15:20:18 fixed by using react-query with offset
          setOffset((prev) => prev + 1);
        }
      },
      { rootMargin: "0px 0px 20px 0px" }
    );
    if (node) intersectionObserver.observe(node);
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const { name, id, artistid } = e.currentTarget.dataset;
    if (name === "mv") {
      if (id && artistid) {
        history.push({
          pathname: `/video/${artistid}/${id}`,
          // state: { mvId: mvid, artistId: artistid },
        });
      }
    } else {
      if (id) {
        setPlayListId(+id);
        setPlayList([]); // Reset playlist before fetching new playlist
        setAlbumInfo(undefined); // Reset albumInfo before jumping to album details page
      }
      history.push({
        pathname: `/playlist/${name}/${id}`,
        // state: { category: name },
      });
    }
  };

  const handleFilterClick = (e: React.MouseEvent<HTMLElement>) => {
    const { name } = e.currentTarget.dataset;
    console.log("e name", name);
    setMoreAlbums([]);
    setOffset(0);
    if (name) setSelectedFilter(name);
  };

  const handleOrderClick = (e: React.MouseEvent<HTMLElement>) => {
    const { name } = e.currentTarget.dataset;
    setMoreAlbums([]);
    setOffset(0);
    if (name) setSelectedOrder(name);
  };

  const handleTypeClick = (e: React.MouseEvent<HTMLElement>) => {
    const { name } = e.currentTarget.dataset;
    console.log(" name", name);
    setMoreAlbums([]);
    setOffset(0);
    if (name) setSelectedType(name);
  };

  console.log("selectedType", selectedType);

  const handleLoadMore = () => {
    setOffset((prev) => prev + 1);
  };

  // Only show loading when offset is the default value 0
  // if (isLoading && offset === 0) {
  //   return (
  //     <Wrapper>
  //       <div className="loading-indicator">
  //         <span>
  //           <RiRunLine />
  //         </span>
  //         <p>{t("common.loading")}</p>
  //       </div>
  //     </Wrapper>
  //   );
  // }

  // isNormalError(error) is used to change the error type from "unknown" to a normal type
  if (isError && isNormalError(error)) {
    return (
      <div>
        <h2>{error.message}</h2>
      </div>
    );
  } else if (isError) {
    return (
      <div>
        <h2>{JSON.stringify(error)}</h2>
      </div>
    );
  }

  return (
    <Wrapper>
      <div className="filter-area">
        {filtersDict[params.type]
          ? filtersDict[params.type].filters.map(
              (filterItem: string, index: number) => (
                <span
                  className="filter-item"
                  key={index}
                  data-name={
                    params.type === "artists"
                      ? filtersDict[params.type].filtersDict[filterItem]
                      : filterItem
                  }
                  onClick={handleFilterClick}
                  style={{
                    backgroundColor:
                      selectedFilter ===
                      (params.type === "artists"
                        ? filtersDict[params.type].filtersDict[filterItem]
                        : filterItem)
                        ? colors.lightGreen
                        : colors.lightGray,
                  }}
                >
                  {filterItem}
                </span>
              )
            )
          : null}
      </div>
      {params.type === "mv" ? (
        <>
          <div className="filter-area">
            {filtersDict[params.type].orders.map(
              (orderItem: string, index: number) => (
                <span
                  className="filter-item"
                  key={index}
                  data-name={orderItem}
                  onClick={handleOrderClick}
                  style={{
                    backgroundColor:
                      selectedOrder === orderItem
                        ? colors.lightGreen
                        : colors.lightGray,
                  }}
                >
                  {orderItem}
                </span>
              )
            )}
          </div>
          {/* <div className="filter-area">
            {filtersDict[params.type].types.map(
              (typeItem: string, index: number) => (
                <span
                  className="filter-item"
                  key={index}
                  data-name={typeItem}
                  onClick={handleTypeClick}
                  style={{
                    backgroundColor:
                      selectedType === typeItem
                        ? colors.lightGreen
                        : colors.lightGray,
                  }}
                >
                  {typeItem}
                </span>
              )
            )}
          </div> */}
        </>
      ) : null}
      {isLoading && offset === 0 ? (
        <div className="loading-indicator">
          <span>
            <RiRunLine />
          </span>
          <p>{t("common.loading")}</p>
        </div>
      ) : (
        <div className={`playlist-category ${params.type}`}>
          {moreAlbums.map((playlist) => (
            <List
              key={playlist.id}
              data-name={params.type}
              data-id={playlist.id}
              data-artistid={playlist.artistId}
              data-action="album"
              onClick={handleClick}
            >
              <div className="image-container">
                <img
                  src={
                    (playlist.coverImgUrl ||
                      playlist.picUrl ||
                      playlist.cover) + "?param=300y300"
                  }
                  alt={params.type}
                />
              </div>
              <p>{playlist.name}</p>
            </List>
          ))}
          {moreAlbums.length <= MAX_ALBUMS_COUNT &&
          !["trends", "recommended"].includes(params.type) ? (
            <div ref={bottomRef} className="dot-indicator">
              {hasMore ? "..." : null}
            </div>
          ) : null}
        </div>
      )}
      {moreAlbums.length > MAX_ALBUMS_COUNT && hasMore ? (
        <div className="load-more">
          <button onClick={handleLoadMore} disabled={isLoading}>
            {t("more-albums.load-more")} {isLoading ? "..." : null}
          </button>
        </div>
      ) : null}
    </Wrapper>
  );
}

export default MoreAlbums;
