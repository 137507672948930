import React, { useContext, useEffect } from "react";
import { MessageContext } from "../context/MessageProvider";
import { Wrapper } from "./Message.styles";

function Message() {
  const messageContext = useContext(MessageContext);
  if (!messageContext) throw new Error();
  const { message, dispatch } = messageContext;
  // console.log("message is", message);

  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch({ type: "none" });
  //   }, 2000);
  // }, []);

  return (
    <Wrapper isActive={!!message.content}>
      <p>{message.content}</p>
    </Wrapper>
  );
}

export default Message;
