export const convertArtists = (artists: string) => {
  return JSON.parse(artists)
    .map((artist: { name: string }) => artist.name)
    .join(",");
};

// Set song name with artists names as the document title
export const documentTitle = (songName: string, artists: string) => {
  //console.log("songName within documenttitle", songName);

  document.title = songName + "-" + artists;
};

// Set song info on mobile chrome notification bar
export const mediaSession = (
  songName: string,
  artists: string,
  albumname: string,
  setIsPlaying: (value: boolean) => void,
  handlePreviousSong: () => void,
  handleNextSong: () => void,
  handleShuffle: () => void,
  playModeIndex: number,
  image: string
) => {
  if ("mediaSession" in navigator && navigator.mediaSession) {
    navigator.mediaSession.metadata = new MediaMetadata({
      title: songName,
      artist: artists,
      album: albumname,
      artwork: [
        {
          src: image,
          sizes: "96x96",
          type: "image/png",
        },
        {
          src: image,
          sizes: "128x128",
          type: "image/png",
        },
        {
          src: image,
          sizes: "192x192",
          type: "image/png",
        },
        {
          src: image,
          sizes: "256x256",
          type: "image/png",
        },
        {
          src: image,
          sizes: "384x384",
          type: "image/png",
        },
        {
          src: image,
          sizes: "512x512",
          type: "image/png",
        },
      ],
    });

    navigator.mediaSession.setActionHandler("play", () => {
      setIsPlaying(true);
    });

    navigator.mediaSession.setActionHandler("pause", function () {
      setIsPlaying(false);
    });

    navigator.mediaSession.setActionHandler("previoustrack", () => {
      if (playModeIndex === 1) return handleShuffle();
      handlePreviousSong();
    });

    navigator.mediaSession.setActionHandler("nexttrack", () => {
      if (playModeIndex === 1) return handleShuffle();
      handleNextSong();
    });
  }
};
