import React, { createContext, ReactNode, useContext, useState } from "react";

interface Props {
  children: ReactNode;
}

interface SearchContextType {
  searchHistory: string[];
  setSearchHistory: (
    searchHistory: string[] | ((prevState: string[]) => string[])
  ) => void;
  searchInfo: string;
  setSearchInfo: (searchInfo: string) => void;
  inputValue: string;
  setInputValue: (inputValue: string) => void;
}
export const SearchContext = createContext<SearchContextType | null>(null);

export function SearchProvider({ children }: Props) {
  const localSearchHistory = localStorage.getItem("searchHistory");

  const [searchHistory, setSearchHistory] = useState<string[]>(
    localSearchHistory ? JSON.parse(localSearchHistory) : []
  );
  const [searchInfo, setSearchInfo] = useState("");
  const [inputValue, setInputValue] = useState("");

  localStorage.setItem("searchHistory", JSON.stringify(searchHistory));

  return (
    <SearchContext.Provider
      value={{
        searchHistory,
        setSearchHistory,
        searchInfo,
        setSearchInfo,
        inputValue,
        setInputValue,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}
