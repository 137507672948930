import React from "react";
import UserProfile from "../components/UserProfile";

function Profile() {
  return (
    <>
      <UserProfile />
    </>
  );
}

export default Profile;
