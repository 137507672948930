import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SongContext } from "../context/SongProvider";

// const localPlayList = localStorage.getItem("playList");
// const localHistoryList = localStorage.getItem("historyList");
// const localAnalyticData = localStorage.getItem("analyticData");

const repairList = [
  { label: "repair.history-list", name: "historyList", checked: true },
  {
    label: "repair.favorite-list",
    name: "favoriteList",
    checked: false,
  },
  { label: "repair.current-list", name: "currentList", checked: true },
  // { label: "repair.playList", name: "playList", checked: false },
  {
    label: "repair.favorite-albums",
    name: "favoriteAlbums",
    checked: false,
  },
  {
    label: "repair.analytic-data",
    name: "analyticData",
    checked: true,
  },
];

const resetList = (listNames: string[]) => {
  listNames.forEach((name) => {
    //const localList = localStorage.getItem(name);
    // if (!localList || localList === "undefined") {
    //   localStorage.setItem(name, "[]");
    // }
    localStorage.removeItem(name);
    // console.log(localStorage.getItem(name));
  });
};

const localResetTriggerDate = localStorage.getItem(
  "resetLocalStorageTriggered"
);

function Repair() {
  const { t } = useTranslation();
  const history = useHistory();

  const initialState = Object.fromEntries(
    repairList.map((item) => [[item.name], item.checked])
  );

  const [isChecked, setIsChecked] = useState(initialState);
  const [
    isResetLocalStorageTriggered,
    setIsResetLocalStorageTriggered,
  ] = useState(
    localResetTriggerDate ? JSON.parse(localResetTriggerDate) : false
  );
  useEffect(() => {
    if (isResetLocalStorageTriggered) {
      setIsResetLocalStorageTriggered(false);
      //history.replace("/");
      setTimeout(() => {
        history.replace("/");
      }, 100);
    }

    // return () => {
    //   localStorage.setItem("resetLocalStorageTriggered", JSON.stringify(false));
    // };
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "resetLocalStorageTriggered",
      JSON.stringify(isResetLocalStorageTriggered)
    );
  }, [isResetLocalStorageTriggered]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget;
    setIsChecked((prev: any) => ({ ...prev, [name]: !prev[name] }));
  };

  const handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();

    const listNames = Object.entries(isChecked).flatMap(([key, value]) =>
      value ? key : []
    );

    //console.log(listNames);
    resetList(listNames);

    setIsResetLocalStorageTriggered(true);
    window.location.reload();
  };

  return (
    <div
      style={{
        marginTop: "60px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p>{t("repair.title")} </p>
      <p>({t("repair.description")})</p>
      <form onSubmit={handleSubmit}>
        {repairList.map((item) => (
          <p key={item.name}>
            <input
              type="checkbox"
              id={item.name}
              name={item.name}
              // value={item.name}
              defaultChecked={isChecked[item.name]}
              onChange={handleChange}
            />
            <label htmlFor={item.name}>{t(item.label)}</label>
            <br />
          </p>
        ))}
        <button>{t("repair.repair")}</button>
      </form>
      {isResetLocalStorageTriggered ? (
        <div
          style={{
            margin: "10px",
            padding: "10px 20px",
            background: "lightgreen",
          }}
        >
          {t("repair.done")}
        </div>
      ) : null}
    </div>
  );
}

export default Repair;
