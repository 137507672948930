import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import "plyr/dist/plyr.css";
import SearchBar from "./components/SearchBar";
import PlayBar from "./components/PlayBar";
import Home from "./pages/Home";
import { SongProvider } from "./context/SongProvider";
import { AlbumProvider } from "./context/AlbumProvider";
import PlayList from "./pages/PlayList";
import Album from "./pages/Album";
import { MessageProvider } from "./context/MessageProvider";
import Message from "./components/Message";
import Modal from "./components/Modal";
import Panel from "./pages/Panel";
import Comment from "./pages/Comment";
import Favorite from "./pages/Favorite";
import Search from "./pages/Search";
import { ErrorBoundary } from "@sentry/react";
import Repair from "./pages/Repair";
import { SearchProvider } from "./context/SearchProvider";
import History from "./pages/History";
import Artist from "./pages/Artist";
import Profile from "./pages/Profile";
import { useTranslation } from "react-i18next";
import Video from "./pages/Video";
import Description from "./pages/Description";
import Analytics from "./pages/Analytics";
import { PlayerProvider } from "./context/PlayerProvider";
import SearchResult from "./pages/SearchResult";
import { colors } from "./utils/styles/global.styles";
// import Search from "./pages/Search";

interface LocationType {
  state: {
    background: {
      pathname: string;
    };
  };
  pathname: string;
}

const mainStyle = {
  width: "100%",
  // height: "100%",
  maxWidth: "800px",
  minHeight: "100vh",
  margin: "0 auto",
  //border: "1px ridge #00000080", //#b1b1b142", //FIXME: not sure why removing this border setting cause the background header and footer not working.
  //borderColor: "#ccc #ccc #ccc transparent",
  background: "#fff",
};

const randomImage = `url(/images/backgrounds/background-${
  Math.floor(Math.random() * 15) + 1
}.jpg)`;

function App() {
  const { i18n } = useTranslation();
  const location: LocationType = useLocation();

  const background = location.state && location.state.background;

  // console.log("location is", location);
  useEffect(() => {
    const browserLanguage = localStorage.getItem("i18nextLng")?.split("-")[0];
    i18n.changeLanguage(browserLanguage);
  }, []);

  useEffect(() => {
    const storage = globalThis.sessionStorage;
    if (!storage) return;
    const prevPath = storage.getItem("currentPath") || "";
    storage.setItem("prevPath", prevPath);
    storage.setItem("currentPath", location.pathname);
  }, [location.pathname]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: Infinity, //5000,
        cacheTime: Infinity, //1000 * 60 * 60 * 24 * 2, // 2 days
      },
    },
  });

  return (
    <div
      style={{
        background: `${colors.lightOrange} ${randomImage} no-repeat fixed`,
        backgroundSize: "cover",
        overflow: "auto", //TODO: this line seems fixed the background image not working with the inner fixed elements issue, need to investigate more.
      }}
    >
      {/* FIXME: backdrop filter below will cause all fixed position element working wrong */}
      {/* <div style={{ backdropFilter: "blur(1px)" }}> */}
      <div style={mainStyle}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <MessageProvider>
              <AlbumProvider>
                <SongProvider>
                  <SearchProvider>
                    <SearchBar />
                    <PlayerProvider>
                      <Switch>
                        <Route exact path="/">
                          <Home />
                        </Route>
                        <Route path={["/playlist/:type/:id", "/playlist"]}>
                          <PlayList />
                        </Route>
                        <Route path="/albums/:type">
                          <Album />
                        </Route>

                        <Route path="/comments/:id">
                          <Comment />
                        </Route>
                        <Route path="/favorite">
                          <Favorite />
                        </Route>
                        <Route path="/search">
                          <Search />
                        </Route>
                        <Route path="/search-result">
                          <SearchResult />
                        </Route>
                        <Route path="/history">
                          <History />
                        </Route>
                        <Route path="/artist">
                          <Artist />
                        </Route>
                        <Route path="/profile">
                          <Profile />
                        </Route>

                        <Route path="/analytics">
                          <Analytics />
                        </Route>
                        {/* <Route path="/description" component={Description} /> */}
                        <Route path={["/panel/:id", "/panel"]}>
                          <Panel />
                        </Route>
                      </Switch>

                      <PlayBar />

                      <Route path="/video/:artistId/:mvId">
                        <Video />
                      </Route>
                    </PlayerProvider>
                    {background && (
                      <Route path={background.pathname}>
                        <Modal />
                      </Route>
                    )}

                    <Message />
                  </SearchProvider>
                </SongProvider>
              </AlbumProvider>
            </MessageProvider>
          </QueryClientProvider>
        </ErrorBoundary>
        <Route path="/repair" component={Repair} />
      </div>
      {/* </div> */}
      <div
        style={{
          position: "fixed",
          right: "10px",
          bottom: "10px",
          color: "#bbb",
        }}
      >
        ©By Ruihua
      </div>
    </div>
  );
}

export default App;
