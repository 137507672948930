import styled from "styled-components";
import { colors } from "../utils/styles/global.styles";

export const Wrapper = styled.div<{ isHomeScreen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  max-width: inherit;
  position: fixed;
  top: 0;
  height: 50px;
  background: #fff;
  box-shadow: 0 1px 10px 1px rgb(0, 0, 0, 0.25);
  z-index: 1;
  cursor: pointer;

  .back-router {
    display: ${({ isHomeScreen }) => (isHomeScreen ? "none" : "block")};
    flex: 10%;
    font-size: 25px;
    text-align: center;
    border-radius: 30%;

    &:active {
      background: ${colors.clickEffect};
      transition: 0.1s;
    }
  }

  .home-icon {
    display: ${({ isHomeScreen }) => (isHomeScreen ? "none" : "block")};
    flex: 10%;
    font-size: 22px;
    text-align: center;
    border-radius: 30%;

    &:active {
      background: ${colors.clickEffect};
      transition: 0.1s;
    }
  }

  .search-bar {
    flex: 65%;
    position: relative;
    margin-left: ${({ isHomeScreen }) => (isHomeScreen ? "10px" : "0")};
    .search-icon svg {
      position: absolute;
      width: 30px;
      height: 40px;
      left: 10px;
    }
  }

  .user-icon {
    flex: 15%;
    text-align: center;
    border-radius: 30%;

    svg {
      position: relative;
      width: 30px;
      height: 40px;
    }

    &:active {
      background: ${colors.clickEffect};
      transition: 0.1s;
    }
  }

  .suggestion-window {
    position: absolute;
    top: 50px;
    min-width: 65%;
    margin: 0 auto;
    padding: 0 10px;
    background: ${colors.darkWhite};

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .suggestion-item {
      padding: 10px;
      border-top: 1px solid ${colors.lightGray};
      font-size: 14px;
    }
  }
`;
export const Input = styled.input`
  width: 100%;
  height: 40px;
  padding-left: 40px;
  outline: none;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 16px;

  &:focus {
    border: solid blue 1.5px;
  }

  &::-webkit-search-cancel-button {
    position: relative;
    right: 10px;
  }
`;
