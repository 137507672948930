import { useContext } from "react";
import { MessageContext } from "../context/MessageProvider";

export const checkMusicAvailability = (
  url: string,
  dispatch: any,
  timeoutId?: number
) => {
  if (!url) {
    clearTimeout(timeoutId);
    dispatch({ type: "play-error" });
    timeoutId = window.setTimeout(() => {
      dispatch({ type: "none" });
    }, 3000);
    return false;
  }
  return true;
};
