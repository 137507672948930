import { keyframes } from "styled-components";

export const colors = {
  lightGreen: "#bee5d3",
  lightBlue: "#c2e9e9", //"#8bcdcd", //"#b6dbe6",
  lightRed: "#ffd4db",
  lightOrange: "#ffd8bf",
  lightGray: "#ccc",
  lightYellow: "#fbf2e3",
  darkGray: "#4c4c4c",
  green: "#73f75a",
  blue: "#2196f3",
  main: "",
  clickEffect: "#eee",
  white: "#fff",
  darkWhite: "#f0eeee",
  brown: "rgb(60, 60, 60, 1)",
};

export const loadingAnimation = keyframes`
  /* 0%{
    left: -50%;
    top: 0%;
  }

  25%{
    left: 0%;
    top: -20%;
  }

  100%{
    left:50%;
     top: 10%;
    transform:translateX(-100%); 
  } */

   0% {
    left:-10%;
  }

  50%{
    left: 0%;
  }

  100%{
    left:10%;
    /* transform:translateX(-100%); */
  }

  /* 100%{
    left: 0
  } */
`;
