import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { RiRocketLine, RiRocket2Line, RiPlantLine } from "react-icons/ri";
import { AiOutlineMore } from "react-icons/ai";
import { VscArrowLeft } from "react-icons/vsc";
import { v4 as uuidv4 } from "uuid";
import { api } from "../services/api";
import { Wrapper } from "./ArtistInfo.styles";
import { SearchContext } from "../context/SearchProvider";
import { getArtistDesc, getArtistIdentity } from "../api/artist";
import { useTranslation } from "react-i18next";

interface LocationType {
  artistId: number;
}

interface ArtistIdentityType {
  name: string;
  cover: string;
  albumSize: number;
  musicSize: number;
}

interface ArtistInfoType {
  ti: string;
  txt: string;
}
function ArtistInfo() {
  const { t } = useTranslation();
  const [artistIdentity, setArtistIdentity] = useState<ArtistIdentityType>({
    name: "",
    cover: "",
    albumSize: 0,
    musicSize: 0,
  });
  const [artistInfo, setArtistInfo] = useState<ArtistInfoType[]>([]);

  const searchContext = useContext(SearchContext);
  if (!searchContext) throw new Error();

  const { setSearchInfo, setInputValue } = searchContext;
  const history = useHistory();
  const location = useLocation<LocationType>();
  const artistId = location.state && location.state.artistId;

  // console.log("artist id is, ", artistId);

  useEffect(() => {
    const fetchArtistInfo = async (id: number) => {
      const infoResponse = await getArtistDesc(id);
      //console.log(infoResponse);
      //  If the infoResponse is empty, set an empty icon
      setArtistInfo(
        infoResponse.data.introduction.length
          ? infoResponse.data.introduction
          : [infoResponse.data.briefDesc]
      );
      const identityResponse = await getArtistIdentity(id);
      setArtistIdentity(identityResponse.data.data.artist);
    };

    fetchArtistInfo(artistId);
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    // if (!(e.target instanceof HTMLElement)) return;
    //console.log(e.currentTarget.innerText);

    const { action } = e.currentTarget.dataset;
    //console.log(action);

    switch (action) {
      case "artist":
        setInputValue(e.currentTarget.innerText); // Used to show the value in input field
        setSearchInfo(uuidv4()); // Used to trigger search
        //history;
        break;
      case "backward":
        history.goBack();
        break;
      case "settings":
        break;
      default:
        console.error("Error, no such action case " + action);
    }
  };

  return (
    <Wrapper>
      <div className="header">
        <div className="back-icon" data-action="backward" onClick={handleClick}>
          <VscArrowLeft />
        </div>
        <div className="page-title">{t("artist.title")}</div>
        {/* <div className="settings" data-action="settings" onClick={handleClick}>
          <AiOutlineMore />
        </div> */}
      </div>

      {artistIdentity.name && (
        <div className="artist-identity">
          <div className="artist-short">
            <div className="artist-name" onClick={handleClick}>
              <span>{artistIdentity.name}</span>
            </div>
            <div className="artist-music">
              <div>
                {t("artist.album-number")}：{artistIdentity.albumSize}
              </div>
              <div>
                {t("artist.song-number")}：{artistIdentity.musicSize}
              </div>
            </div>
          </div>
          <div className="artist-cover">
            <img
              src={artistIdentity.cover + "?param=300y300"}
              alt={artistIdentity.name}
            />
          </div>
        </div>
      )}
      {artistInfo[0] && artistInfo[0].txt ? (
        artistInfo.map((info, index) => (
          <div key={index}>
            <div className="title">{info.ti}</div>
            <div className="content">
              {info.txt.split("\n").map((item, index) => (
                <div className="split-content" key={index}>
                  {item}
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <div className="loading-indicator">
          {artistInfo.length ? (
            <>
              <span>
                <RiPlantLine />
              </span>
              <div className="title">{t("artist.brief-description")} </div>
              <p>{artistInfo[0]}</p>
            </>
          ) : (
            <>
              <span>
                <RiRocket2Line />
              </span>
              <p>{t("common.loading")} </p>
            </>
          )}
        </div>
      )}
    </Wrapper>
  );
}

export default ArtistInfo;
