import React, { createContext, ReactNode, useContext, useReducer } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  children: ReactNode;
}

interface StateType {
  type: string;
  content: string;
}

interface ActionType {
  type: string;
}

type MessageContextType = {
  message: StateType;
  dispatch: (action: ActionType) => void;
};

export const MessageContext = createContext<MessageContextType | null>(null); //useContext()

const initialState = {
  type: "",
  content: "",
};

export function MessageProvider({ children }: Props) {
  const { t } = useTranslation();

  const reducer = (state: StateType, action: ActionType) => {
    switch (action.type) {
      case "success":
        return { ...state, type: "success", content: t("message.success") };
      case "search-error":
        return { ...state, type: "error", content: t("message.search-error") };
      case "play-error":
        return {
          ...state,
          type: "error",
          content: t("message.play-error"),
        };
      case "noComment-error":
        return {
          ...state,
          type: "error",
          content: t("message.noComment-error"),
        };
      case "noMoreComment-error":
        return {
          ...state,
          type: "error",
          content: t("message.noMoreComment-error"),
        };
      case "duplicate-error":
        return {
          ...state,
          type: "error",
          content: t("message.duplicate-error"),
        };
      case "share-error":
        return {
          ...state,
          type: "error",
          content: t("message.share-error"),
        };
      case "dev-error":
        return {
          ...state,
          type: "error",
          content: t("message.dev-error"),
        };
      case "add-fav":
        return {
          ...state,
          type: "success",
          content: t("message.add-fav"),
        };
      case "remove-fav":
        return {
          ...state,
          type: "success",
          content: t("message.remove-fav"),
        };
      case "under-development":
        return {
          ...state,
          type: "success",
          content: t("message.under-development"),
        };
      case "none":
        return { ...state, type: "", content: "" };
      default:
        throw new Error("Unexpected action");
    }
  };

  const [message, dispatch] = useReducer(reducer, initialState);

  return (
    <MessageContext.Provider value={{ message, dispatch }}>
      {children}
    </MessageContext.Provider>
  );
}
