import React, { useContext } from "react";
import { SongContext } from "../context/SongProvider";
import { Wrapper } from "./PlaylistDescription.styles";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from "react-router";

function PlaylistDescription() {
  const history = useHistory();
  const songContext = useContext(SongContext);
  if (!songContext) throw new Error();
  const { albumInfo } = songContext;

  const handleClick = () => {
    history.goBack();
  };
  return (
    <Wrapper
      style={{
        backgroundColor: albumInfo?.color ? albumInfo.color : "#393e46",
      }}
      onClick={handleClick}
    >
      <div className="close-icon">
        <AiOutlineClose />
      </div>
      {albumInfo ? (
        <div className="container">
          <div className="image-container">
            <img
              src={albumInfo.cover + "?param=600y600"}
              alt={albumInfo.name}
            />
          </div>
          <div className="text-container">
            <div className="title">{albumInfo.name}</div>
            <div className="description">{albumInfo.description}</div>
          </div>
        </div>
      ) : null}
    </Wrapper>
  );
}

export default PlaylistDescription;
