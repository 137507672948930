export const throttle = (fun: any, delay: number) => {
  let timeoutId = 0;

  return (...args: any) => {
    if (timeoutId) return;
    timeoutId = window.setTimeout(() => {
      fun(...args), (timeoutId = 0);
    }, delay);
  };
};

export const debounce = (fun: any, delay: number) => {
  let timeoutId = 0;

  return (...args: any) => {
    clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => fun(...args), delay);
  };
};
