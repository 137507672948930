import { api } from "../services/api";

interface PlaylistParams {
  order?: string;
  limit: number;
  offset?: number;
  cat?: string;
}

// For order param, the default is "hot", and the other option is "new"
export const getTopPlaylist = (params: PlaylistParams) => {
  return api({
    method: "get",
    url: "/top/playlist",
    params,
  });
};

export const getRecommendedPlaylist = (params: PlaylistParams) => {
  return api({
    method: "get",
    url: "/personalized",
    params,
  });
};

export const getTrendPlaylist = () => {
  return api({
    method: "get",
    url: "/toplist",
  });
};

export const getPlaylistDetails = (id: number) => {
  return api({
    method: "get",
    url: "/playlist/detail",
    params: {
      id,
    },
  });
};
