import styled from "styled-components";

export const Wrapper = styled.div<{ isActive: boolean }>`
  display: ${({ isActive }) => (isActive ? "block" : "none")};
  position: fixed;
  bottom: 80px;
  width: inherit;
  max-width: inherit;
  color: #ddd;
  z-index: 2000;

  p {
    width: max-content;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.8)
    );
    margin: 0 auto;
    padding: 8px 15px 10px 15px;
    border-radius: 25px;
    font-size: 14px;
  }
`;
