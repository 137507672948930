import React from "react";

function MainContainer({ children }: { children: React.ReactNode }) {
  return (
    <div style={{ margin: "50px 0", paddingTop: "2px", paddingBottom: "10px" }}>
      {children}
    </div>
  );
}

export default MainContainer;
