import { api } from "../services/api";

interface BannerParams {
  type: number;
}

interface MusicCommentParams {
  id: number;
  type: number;
  offset: number;
}

interface SearchParams {
  keywords: string;
  type?: number;
  limit?: number;
  offset?: number;
}
export const getBanners = (params: BannerParams) => {
  return api({
    method: "get",
    url: "/banner",
    params,
  });
};

export const getLyric = (id: number) => {
  return api({
    method: "get",
    url: "/lyric",
    params: {
      id,
    },
  });
};

export const getMusicHotComments = (params: MusicCommentParams) => {
  return api({
    method: "get",
    url: "/comment/hot",
    params,
  });
};

export const getSearchResults = (params: SearchParams) => {
  return api({
    method: "get",
    url: "/cloudsearch",
    params,
  });
};

export const getSearchSuggestions = (param: string) => {
  return api({
    method: "get",
    url: "/search/suggest",
    params: {
      keywords: param,
    },
  });
};
