import React, { useState } from "react";

export const useDelay = (delay: number) => {
  const [isDelayOver, setIsDelayOver] = useState(false);

  setTimeout(() => {
    setIsDelayOver(true);
  }, delay);

  return { isDelayOver };
};
