import { api } from "../services/api";

// Album is for artists, and playlist is for users, so we need to use different url
export const getAlbumDetails = (id: number) => {
  return api({
    url: "/album",
    method: "get",
    params: {
      id,
    },
  });
};
