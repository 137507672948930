import React, { useEffect, useState, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  AiOutlineFolderAdd,
  AiOutlineDelete,
  AiOutlineDownload,
  AiOutlineMessage,
  AiOutlineUser,
  AiOutlineRobot,
  AiOutlineAlignLeft,
  AiOutlineShareAlt,
  AiOutlinePlaySquare
} from "react-icons/ai";
import { useHistory, useLocation } from "react-router-dom";
import { MessageContext } from "../context/MessageProvider";
import { SongContext } from "../context/SongProvider";
import { api } from "../services/api";
import { Li, Wrapper } from "./SingleSongItemAction.styles";

function SingleSongItemAction() {
  const { t } = useTranslation();
  const [songBlobURL, setSongBlobURL] = useState("");
  const [downloadName, setDownloadName] = useState("");

  const downloadRef = useRef<HTMLAnchorElement>(null);

  const history = useHistory();
  const location = useLocation();

  const songContext = useContext(SongContext);
  const messageContext = useContext(MessageContext);

  if (!songContext || !messageContext) throw new Error();
  const {
    songActionInfo,
    favoriteList,
    setFavoriteList,
    playList,
    historyList,
    setPlayListId,
  } = songContext;
  const { dispatch } = messageContext;

  const { id, index, name, artists, artistId, mvId } = songActionInfo;

  //console.log("id, index, name, artists", id, index, name, artists, artistId, mvId);
  // console.log(artists && convertArtists(artists), typeof artists);

  useEffect(() => {
    songBlobURL && downloadRef.current && downloadRef.current.click();
    // console.log("rendered");
  }, [songBlobURL && downloadName]);

  const handleClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const { action } = e.currentTarget.dataset;
    switch (action) {
      case "favorite":
        // check Duplicates
        // if (
        //   favoriteList &&
        //   favoriteList.findIndex((item) => item.id === id) !== -1
        // ) {
        //   dispatch({ type: "duplicate-error" });
        //   return setTimeout(() => {
        //     dispatch({ type: "none" });
        //   }, 3000);
        // }
        const newItem =
          playList.find((item) => item.id === id) ||
          historyList.find((item) => item.id === id);
        console.log(newItem);

        newItem && setFavoriteList((prev) => [...prev, newItem]);
        dispatch({ type: "add-fav" });
        setTimeout(() => {
          dispatch({ type: "none" });
        }, 3000);
        history.goBack();
        break;
      case "remove":
        setFavoriteList((prev) => prev.filter((item) => item.id !== id));
        dispatch({ type: "remove-fav" });
        setTimeout(() => {
          dispatch({ type: "none" });
        }, 3000);
        history.goBack();
        break;
      case "share":
        if (navigator.share && id) {
          navigator
            .share({
              title: name,
              text: `${name}-${artists} ${window.location.hostname}/panel/${id}`,
            })
            .then()
            .catch((error) => console.error(error));
        } else {
          dispatch({ type: "share-error" });
          return setTimeout(() => {
            dispatch({ type: "none" });
          }, 3000);
        }

        break;
      case "download":
        try {
          if (id && name && artists) {
            const returnedURL = await api.get(`/song/url?id=${id}`);
            const response = await api.get(returnedURL.data.data[0].url, {
              responseType: "blob",
            });
            const result = URL.createObjectURL(response.data);
            setTimeout(() => {
              URL.revokeObjectURL(result);
            }, 1000);
            setSongBlobURL(result);
            console.log(response, result);
            setDownloadName(name + "-" + artists);
          }
        } catch (error) {
          console.error(error);
        }
        break;
      case "video":
        if (mvId && artistId) {
          history.replace({ pathname: "/video", state: { mvId, artistId } });
        }
        break;
      case "artist":
        if (artistId) {
          // setPlayListId(artistId);
          history.replace({
            pathname: "/artist",
            state: { artistId },
          });
        }

        break;
      case "album":
        break;
      case "comment":
        history.replace({
          pathname: `/comments/${id}`,
          // state: { songId: id },
        });
        break;
      case "lyric":
        history.replace("/panel");
        break;
      case "cancel":
        history.goBack();
        break;
      default:
        console.error("Error, no such case");
    }
  };

  //console.log("isModalActive", isModalActive);

  return (
    <Wrapper>
      <div className="title">
        {name}-{artists}
      </div>
      <ul>
        {favoriteList && favoriteList.some((item) => item.id === id) ? (
          <li data-action="remove" onClick={handleClick}>
            <span>
              <AiOutlineDelete />
            </span>
            <span>{t("action-panel.remove")} </span>
          </li>
        ) : (
          <li data-action="favorite" onClick={handleClick}>
            <span>
              <AiOutlineFolderAdd />
            </span>
            <span>{t("action-panel.favorite")}</span>
          </li>
        )}
        <li data-action="share" onClick={handleClick}>
          <span>
            <AiOutlineShareAlt />
          </span>
          <span>{t("action-panel.share")}</span>
        </li>
        <li data-action="download" onClick={handleClick}>
          <span>
            <AiOutlineDownload />
          </span>
          <span>{t("action-panel.download")}</span>
        </li>

        <Li mvId={mvId} data-action="video" onClick={handleClick}>
          <span>
            <AiOutlinePlaySquare />
          </span>
          <span>{t("action-panel.video")}</span>
        </Li>
        <li className="artist" data-action="artist" onClick={handleClick}>
          <span>
            <AiOutlineUser />
          </span>
          <span>{t("action-panel.artist")}</span>
        </li>
        {/* <li className="album" data-action="album" onClick={handleClick}>
          <span>
            <AiOutlineRobot />
          </span>
          <span>{t("action-panel.album")}</span>
        </li> */}
        <li data-action="comment" onClick={handleClick}>
          <span>
            <AiOutlineMessage />
          </span>
          <span>{t("action-panel.comment")}</span>
        </li>
        {/* <li data-action="lyric" onClick={handleClick}>
          <span>
            <AiOutlineAlignLeft />
          </span>
          <span>Lyric</span>
        </li> */}
        <li className="cancel" data-action="cancel" onClick={handleClick}>
          {/* <span>
            <AiOutlineMessage />
          </span> */}
          <div className="cancel-inner">{t("action-panel.cancel")}</div>
        </li>
      </ul>
      <div className="download-link">
        <a
          href={songBlobURL}
          target="_blank"
          rel="noopener noreferrer"
          download={downloadName}
          ref={downloadRef}
        ></a>
      </div>
    </Wrapper>
  );
}

export default SingleSongItemAction;
