import styled from "styled-components";
import { colors } from "../utils/styles/global.styles";

export const Wrapper = styled.div`
  text-align: left;
  margin-bottom: 10px;
  overscroll-behavior: contain;
  background: ${colors.lightYellow};
  /* min-height: 45vh; */
  width: inherit;
  max-width: inherit;

  .title-area {
    padding: 10px 5%;
    font-size: 16px;
    font-weight: 600;
    position: sticky;
    top: 0;
    background: ${colors.lightYellow};
    box-shadow: 0 1px 2px 1px rgb(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-around;
    align-items: center;

    .title {
      flex: 70%;
    }

    .favorite {
      flex: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        font-size: 20px;
      }

      &:hover {
        background: ${colors.clickEffect};
      }

      &:active {
        background: ${colors.clickEffect};
        transition: 0.1s;
      }
    }

    .delete {
      flex: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        font-size: 20px;
      }

      &:active {
        background: ${colors.clickEffect};
        transition: 0.1s;
      }
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px; // to avoid "cancel" action cover the last list
  }

  .current-indicator-icon {
    font-size: 20px;
    color: ${colors.green}; //#ffff00;
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    .song-item {
      flex: 90%;
      display: flex;
      justify-content: center;
      align-items: center;

      .song-index {
        flex: 5%;
        text-align: center;
      }

      .song-details {
        flex: 80%;

        .song-title {
          font-size: 15px;
        }

        .artist-name {
          font-size: 12px;
        }
      }

      &:active {
        background: ${colors.clickEffect};
        transition: 0.1s;
      }
    }

    .delete-song {
      flex: 10%;
      text-align: center;
      font-size: 25px;

      &:active {
        background: ${colors.clickEffect};
        transition: 0.1s;
      }
    }

    /* &:hover,
    :active {
      background: #ddd;
    } */
  }

  .cancel {
    /* width: 100%; */
    width: inherit;
    max-width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 5px solid #eee;
    position: fixed;
    bottom: 0;
    padding: 12px 0;
    background: ${colors.lightYellow};
  }
`;

export const EmptyStatus = styled.div<{ isClicked: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 40%;
  height: 300px;

  .gift-icon {
    font-size: 50px;
    cursor: pointer;
    animation: giftShake 1s ease 1s 5;

    /* &:hover {
      animation: giftShake 1s ease 2;
    } */

    @keyframes giftScale {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.6);
      }
      100% {
        transform: scale(1);
      }
    }

    @keyframes giftShake {
      0% {
        transform: translate(1px, 1px) rotate(0deg);
      }
      10% {
        transform: translate(-1px, -2px) rotate(-1deg);
      }
      20% {
        transform: translate(-3px, 0px) rotate(1deg);
      }
      30% {
        transform: translate(3px, 2px) rotate(0deg);
      }
      40% {
        transform: translate(1px, -1px) rotate(1deg);
      }
      50% {
        transform: translate(-1px, 2px) rotate(-1deg);
      }
      60% {
        transform: translate(-3px, 1px) rotate(0deg);
      }
      70% {
        transform: translate(3px, 1px) rotate(-1deg);
      }
      80% {
        transform: translate(-1px, -1px) rotate(1deg);
      }
      90% {
        transform: translate(1px, 2px) rotate(0deg);
      }
      100% {
        transform: translate(1px, -2px) rotate(-1deg);
      }
    }
  }
`;
