import React, { useEffect, useState, useContext } from "react";
import {
  SlideControl,
  Wrapper,
  CarouselItem,
  SlideIndicator,
  NewWrapper,
} from "./Carousel.styles";
import { MessageContext } from "../context/MessageProvider";
import { SongContext } from "../context/SongProvider";
import { useHistory } from "react-router-dom";
import { SearchContext } from "../context/SearchProvider";
import { useQuery } from "react-query";
import { getBanners } from "../api/others";
import { shuffleArray } from "../utils/shuffle";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

interface CarouselItem {
  bannerId: number;
  pic: string;
  targetId: number;
  song: {
    name?: string;
  };
}

SwiperCore.use([Pagination]);

function Carousel() {
  // const [carouselIndex, setCarouselIndex] = useState(0);
  // const [touchStart, setTouchStart] = useState(0);
  // const [isPause, setIsPause] = useState(false);
  // const [moveLength, setMoveLength] = useState(0);

  const { isLoading, isSuccess, data } = useQuery("carousel", () =>
    getBanners({ type: 2 })
  );

  const carouselList: CarouselItem[] = isSuccess
    ? (shuffleArray(data?.data.banners) as CarouselItem[]).slice(0, 8)
    : [];

  const messageContext = useContext(MessageContext);
  const songContext = useContext(SongContext);
  const searchContext = useContext(SearchContext);

  if (!messageContext || !songContext || !searchContext) throw new Error();
  const { dispatch } = messageContext;
  const { setInputValue, setSearchInfo } = searchContext;

  // To auto switch of sliders
  // useEffect(() => {
  //   const carouselLength = carouselList?.length;
  //   if (carouselLength) {
  //     console.log(isPause);

  //     const timer = setInterval(() => {
  //       !isPause &&
  //         setCarouselIndex((prev) =>
  //           prev < carouselLength - 1 ? prev + 1 : 0
  //         );
  //     }, 5000);

  //     return () => clearInterval(timer);
  //   }
  // }, [carouselList, isPause]);

  // const handleClick = (e: React.MouseEvent<HTMLElement>) => {
  //   // if (!(e.target instanceof HTMLElement)) return;
  //   const { action, index, id, name } = e.currentTarget.dataset;

  //   switch (action) {
  //     case "carousel":
  //       if (name) {
  //         // setPlayListId(+id);
  //         // history.push({
  //         //   pathname: "/playlist",
  //         //   state: { category: "banner" },
  //         // });
  //         setInputValue(name);
  //         setSearchInfo(name);
  //       } else {
  //         dispatch({ type: "dev-error" });
  //         setTimeout(() => {
  //           dispatch({ type: "none" });
  //         }, 3000);
  //       }

  //       break;
  //     case "dot-indicator":
  //       if (index) {
  //         setCarouselIndex(+index);
  //       }
  //       break;
  //     default:
  //       console.error("Error, no such case");
  //   }
  // };

  // const handleTouchStart = (e: React.TouchEvent<HTMLElement>) => {
  //   console.log("touch start");
  //   setTouchStart(e.targetTouches[0].clientX);
  //   setIsPause(true);
  // };

  // // Used for carousel length
  // const handleTouchMove = (e: React.TouchEvent<HTMLElement>) => {
  //   console.log("touch move");
  //   setMoveLength(e.targetTouches[0].clientX - touchStart);
  // };

  // const handleTouchEnd = (e: React.TouchEvent<HTMLElement>) => {
  //   console.log("touch end");
  //   setIsPause(false);
  //   const moveDistance = e.changedTouches[0].clientX - touchStart;

  //   // Slide from right to left (move to right)
  //   if (moveDistance < -50) {
  //     setCarouselIndex((prev) =>
  //       prev < carouselList.length - 1 ? prev + 1 : 0
  //     );
  //   }

  //   //Slide from left to right (move to left)
  //   if (moveDistance > 50) {
  //     setCarouselIndex((prev) =>
  //       prev > 0 ? prev - 1 : carouselList.length - 1
  //     );
  //   }
  // };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const { name } = e.currentTarget.dataset;
    if (name) {
      setInputValue(name);
      setSearchInfo(name);
    } else {
      dispatch({ type: "dev-error" });
      setTimeout(() => {
        dispatch({ type: "none" });
      }, 3000);
    }
  };

  if (isLoading) return null;

  return (
    <NewWrapper>
      <Swiper pagination={true} loop={true}>
        {carouselList.map((item, index) => (
          <SwiperSlide
            key={index}
            data-name={item.song?.name}
            onClick={handleClick}
          >
            <img src={item.pic} alt="banner" />
          </SwiperSlide>
        ))}
      </Swiper>
    </NewWrapper>
  );

  // return (
  //   <Wrapper
  //     carouselIndex={+carouselIndex}
  //     isTouchEnd={!isPause}
  //     moveLength={moveLength}
  //   >
  //     <div className="carousel-track">
  //       {carouselList.map((item, index: number) => (
  //         <CarouselItem
  //           key={item.bannerId}
  //           data-index={index}
  //           data-id={item.targetId}
  //           data-name={item.song?.name}
  //           data-action="carousel"
  //           isActive={index === +carouselIndex}
  //           onClick={handleClick}
  //           onTouchStart={handleTouchStart}
  //           onTouchMove={handleTouchMove}
  //           onTouchEnd={handleTouchEnd}
  //         >
  //           <img src={item.pic} alt="banner" />
  //         </CarouselItem>
  //       ))}
  //     </div>

  //     <SlideControl>
  //       <div className="dot-list">
  //         {carouselList.map((item, index: number) => (
  //           <SlideIndicator
  //             isActive={index === +carouselIndex}
  //             key={item.bannerId}
  //             data-index={index}
  //             data-action="dot-indicator"
  //             onClick={handleClick}
  //           ></SlideIndicator>
  //         ))}
  //       </div>
  //     </SlideControl>
  //   </Wrapper>
  // );
}

export default Carousel;
