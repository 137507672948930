import styled from "styled-components";
import { colors, loadingAnimation } from "../utils/styles/global.styles";

export const Wrapper = styled.div`
  .container {
    .category-area {
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      margin: 15px;
      display: flex;
      align-items: center;
      text-transform: capitalize;

      .category-indicator {
        display: inline-block;
        flex: 0 0 1.5%;
        max-width: 10px;
        height: 20px;
        background: red;
        margin-right: 10px;
      }

      .category-title {
        flex: 15%;
      }

      .category-more {
        flex: 80%;
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        color: ${colors.darkGray};
        cursor: pointer;

        span {
          vertical-align: middle;
          padding-left: 2px;
        }

        &:active {
          background: ${colors.clickEffect};
          transition: 0.1s;
        }
      }
    }
    .playlist-category {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      margin: 10px 0;
    }

    .artists img {
      border-radius: 50%;
    }
  }

  .loading-indicator {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      font-size: 50px;
      position: relative;
      animation: ${loadingAnimation} 6s linear 0s infinite;
    }
  }
`;

export const List = styled.div`
  flex: 33%;
  cursor: pointer;

  .image-container {
    height: 120px;
    padding: 0 5px;
    /* border-radius: 15px;
    overflow: hidden; */
    position: relative;

    .video-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 50px;
      color: ${colors.lightGreen};
      /* background: ${colors.darkWhite}; */
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .short-description {
    /* max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 12px;
    line-height: 18px;
    height: 36px;
    margin: 10px 3px;
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  @media (min-width: 600px) {
    //flex: 16%;
    .image-container {
      height: 150px;
    }
  }
`;
