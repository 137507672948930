import styled from "styled-components";
import { colors, loadingAnimation } from "../utils/styles/global.styles";

export const Wrapper = styled.div`
  .filter-area {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .filter-item {
      padding: 5px 10px;
      border-radius: 15px;
      margin: 3px;
      font-size: 14px;
    }
  }

  .playlist-category {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0;
  }

  .artists img {
    border-radius: 50%;
  }

  .dot-indicator {
    width: 50%;
    text-align: center;
    font-size: 30px;
    position: relative;
    animation: ${loadingAnimation} 3s linear 0s infinite;
  }

  .load-more {
    margin: 50px 0 80px 0;
    text-align: center;

    button {
      padding: 10px 15px;
      background: ${colors.lightGreen};
      //color: ${colors.white};
      border: none;
      cursor: pointer;
    }
  }

  .loading-indicator {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-size: 50px;
      position: relative;
      animation: ${loadingAnimation} 6s linear 0s infinite;
    }
  }
`;

export const List = styled.div`
  flex: 33%;
  cursor: pointer;

  .image-container {
    height: 120px;
    padding: 0 5px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  p {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    margin: 10px auto;
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  /* @media (min-width: 1000px) {
    flex: 16%;
  } */
`;
