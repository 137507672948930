import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  AiOutlineArrowLeft,
  AiOutlineSearch,
  AiOutlineUser,
  AiOutlineHome,
} from "react-icons/ai";
import { RiHome2Line } from "react-icons/ri";
import { useHistory, useLocation } from "react-router-dom";
import { getSearchResults, getSearchSuggestions } from "../api/others";
import { MessageContext } from "../context/MessageProvider";
import { SearchContext } from "../context/SearchProvider";
import { SongContext } from "../context/SongProvider";
import { api } from "../services/api";
import { Wrapper, Input } from "./SearchBar.styles";
import { useQuery } from "react-query";

function SearchBar() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [isFocused, setIsFocused] = useState(false);

  const songContext = useContext(SongContext);
  const messageContext = useContext(MessageContext);
  const searchContext = useContext(SearchContext);

  if (!songContext || !messageContext || !searchContext) throw new Error();
  const { setPlayList, setAlbumInfo } = songContext;
  const { dispatch } = messageContext;
  const {
    setSearchHistory,
    searchInfo,
    setSearchInfo,
    inputValue,
    setInputValue,
  } = searchContext;

  const { data: suggestions } = useQuery(
    ["searchSuggestion", inputValue],
    () => getSearchSuggestions(inputValue),
    { enabled: !!inputValue && isFocused }
  );

  // console.log("suggestions is", suggestions);

  useEffect(() => {
    handleSearch();
  }, [searchInfo]);

  const handleSearch = async () => {
    // Only trigger search when there is an inputValue
    if (inputValue === "repair!!!") {
      setInputValue("");
      history.push("/repair");
      return;
    }
    if (inputValue) {
      setAlbumInfo(undefined); // Reset albumInfo before jumping to album details page
      setSearchHistory((prev) => [
        inputValue,
        ...prev.filter((item) => item !== inputValue),
      ]);
      // try {
      //   const response = await getSearchResults({ keywords: inputValue });
      //   //   api.get(
      //   //   `/cloudsearch?keywords=${inputValue}&limit=100`
      //   // );
      //   console.log(response);
      //   if (response.data.result && !_.isEmpty(response.data.result)) {
      //     setPlayList(response.data.result.songs);
      //   } else {
      //     dispatch({ type: "search-error" });
      //     setTimeout(() => {
      //       dispatch({ type: "none" });
      //     }, 3000);
      //   }
      // } catch (error) {
      //   console.error(error);
      // }

      // history.push({ pathname: "/search", search: `?${inputValue}` });
      history.push("/search-result");
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter") {
      handleSearch();
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const { name } = e.currentTarget.dataset;
    switch (name) {
      case "back-icon":
        history.goBack();
        // history.push("/");
        // setPlayList([]);
        // setIsProfileModalActive(false);
        break;
      case "home-icon":
        history.replace("/");
        break;
      case "user-icon":
        // console.log("user icon is clicked");
        if (location.pathname !== "/profile") {
          history.push("/profile");
        } else {
          // history.push({
          //   pathname: location.pathname,
          //   search: "?profile",
          //   state: { background: location },
          // });
        }

        //setIsProfileModalActive((prev) => !prev);
        break;
      default:
        console.error("Error, no such case");
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    history.push("/search");
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleSuggestionClick = (e: React.MouseEvent<HTMLElement>) => {
    const { value } = e.currentTarget.dataset;
    //console.log("value is", value);

    if (value) {
      setInputValue(value);
      setSearchInfo(value);
    }
  };

  return (
    <Wrapper isHomeScreen={location.pathname === "/"}>
      <div
        className="back-router"
        // key={history.location.pathname}
        data-name="back-icon"
        onClick={handleClick}
      >
        <AiOutlineArrowLeft />
      </div>
      <div className="home-icon" data-name="home-icon" onClick={handleClick}>
        <RiHome2Line />
        {/* <AiOutlineHome/> */}
      </div>
      <div className="search-bar" onKeyPress={handleKeyPress}>
        <span className="search-icon" onClick={handleSearch}>
          <AiOutlineSearch />
        </span>
        <Input
          type="search"
          placeholder={t("search-bar.placeholder")}
          value={inputValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>

      <div className="user-icon" data-name="user-icon" onClick={handleClick}>
        <AiOutlineUser />
      </div>
      {isFocused && inputValue && (
        <div className="suggestion-window">
          <ul>
            {suggestions?.data.result?.artists?.map(
              (artist: any, index: number) => (
                <li
                  key={index}
                  className="suggestion-item"
                  data-value={artist.name}
                  onMouseDown={handleSuggestionClick} // The reason not using onClick is because onClick will be triggered after onBlur on input element, thus the suggestion window will be closed before onClick is triggered
                >
                  {artist.name}
                </li>
              )
            )}
            {suggestions?.data.result?.songs?.map(
              (song: any, index: number) => (
                <li
                  key={index}
                  className="suggestion-item"
                  data-value={song.name}
                  onMouseDown={handleSuggestionClick}
                >
                  {song.name}
                </li>
              )
            )}
          </ul>
        </div>
      )}
    </Wrapper>
  );
}

export default SearchBar;
