import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { SongContext } from "../context/SongProvider";
import { Wrapper } from "./ConfirmModal.styles";

interface LocationType {
  state: {
    originPage: string;
  };
}

function ConfirmModal() {
  const { t } = useTranslation();
  const history = useHistory();
  const location: LocationType = useLocation();

  // console.log(history, location);

  const songContext = useContext(SongContext);
  if (!songContext) throw new Error();
  const { setCurrentList, setFavoriteList, setHistoryList } = songContext;

  const locationState = location.state;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!(e.target instanceof HTMLElement)) return;
    const { action } = e.target.dataset;

    switch (action) {
      case "cancel":
        //history.goBack();
        break;
      case "delete":
        if (locationState.originPage === "currentList")
          return setCurrentList([]);

        // if (locationState.originPage === "userProfile")
        //   return setHistoryList([]); // setFavoriteList([]);

        if (locationState.originPage === "/favorite")
          return setFavoriteList([]);
        if (locationState.originPage === "/history") return setHistoryList([]);

        //history.goBack();
        break;
      default:
        console.error("Error, no such case");
    }
  };
  return (
    <Wrapper>
      <div> {t("confirm-modal.title")} </div>
      <div className="buttons" onClick={handleClick}>
        <button className="cancel" data-action="cancel">
          {t("confirm-modal.cancel")}
        </button>
        <button className="delete" data-action="delete">
          {t("confirm-modal.delete")}
        </button>
      </div>
    </Wrapper>
  );
}

export default ConfirmModal;
