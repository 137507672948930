import styled from "styled-components";
import { randomColor } from "../utils/generator";
import { colors } from "../utils/styles/global.styles";

export const Wrapper = styled.div`
  /* display: flex; */
  width: inherit;
  max-width: inherit;
  /* height: 60px; */
  position: fixed;
  bottom: 0;
  box-shadow: 0 -1px 20px 1px rgb(0, 0, 0, 0.25);
  background: #fff;
  z-index: 1;
  text-align: center;
  cursor: pointer;

  .music-progress {
    progress {
      width: 100%;
      appearance: none;
      height: 3px;
      display: block;

      &::-webkit-progress-bar {
        color: red;
        background: #ccc;
      }

      &::-webkit-progress-value {
        background: ${randomColor()};
      }
    }

    .music-duration {
      font-size: 10px;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
    }
  }

  .music-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;

    .left {
      flex: 0 1 75%;
      max-width: 75%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;

      .current-music-cover {
        //width: 60px;
        height: 45px;
        padding: 3px 5px;
        flex: 0 0 60px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .music-short-info {
        padding-left: 5px;
        max-width: 70%;

        .song-name {
          font-size: 14px;
          font-weight: 600;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .song-artist {
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .right {
      flex: 0 0 25%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 20px;
      padding-right: 20px;

      .play-control {
        margin-right: 25px;
      }

      .play-pause {
        min-width: 30px;
        height: 30px;
        /* font-size: 30px; */
        border: 1px solid ${colors.darkGray};
        border-radius: 50%;

        svg {
          padding-left: 2px;
          padding-top: 4px;
        }
      }
    }

    /* div {
      flex: 0 0 16%;
      font-size: 20px;
      border-radius: 30%;

      &:active {
        background: ${colors.clickEffect};
        transition: 0.1s;
      }
    } */
  }
`;
