import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import { SearchContext } from "../context/SearchProvider";
import { api } from "../services/api";
import { Wrapper } from "./SearchInfo.styles";

interface HotSearch {
  searchWord: string;
}

function SearchInfo() {
  const { t } = useTranslation();
  const [hotSearch, setHotSearch] = useState<HotSearch[]>([]);

  const searchContext = useContext(SearchContext);
  if (!searchContext) throw new Error();

  const {
    searchHistory,
    setSearchHistory,
    searchInfo,
    setSearchInfo,
    setInputValue,
  } = searchContext;

  useEffect(() => {
    const fetchHotSearchKeywords = async () => {
      const response = await api.get("/search/hot/detail");
      setHotSearch(response.data.data);
    };

    fetchHotSearchKeywords();
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    //if (!(e.target instanceof HTMLElement)) return;
    //const { value } = e.currentTarget.dataset;

    const value = e.currentTarget.innerText;
    if (value) {
      setInputValue(value); // Used to show the value in input field
      setSearchInfo(uuidv4()); // Used to trigger search
    }
  };

  const handleDelete = () => {
    setSearchHistory([]);
  };
  return (
    <Wrapper>
      <div className="search-history">
        {searchHistory.length ? (
          <>
            <div className="title history-title">
              <span>{t("search.history")}</span>
              <span onClick={handleDelete}>
                <AiOutlineDelete />
              </span>
            </div>
            <ul>
              {searchHistory.map((item, index) => (
                <li key={index} data-value={item} onClick={handleClick}>
                  {item}
                </li>
              ))}
            </ul>
          </>
        ) : null}
      </div>
      <div className="search-hot">
        <div className="title hot-title">{t("search.hot")}</div>
        {hotSearch.length ? (
          <ul>
            {hotSearch.map((item, index) => (
              <li key={index}>
                <span className="hot-index">{index + 1}</span>
                <span className="hot-item" onClick={handleClick}>
                  {item.searchWord}
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <p>{t("common.loading")}</p>
        )}
      </div>
    </Wrapper>
  );
}

export default SearchInfo;
