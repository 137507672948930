import axios from "axios";

export const domain = "music-api.niuruihua.com"; //"react-music-express.vercel.app/";
console.log(process.env.NODE_ENV);

export const URL = `https://${domain}`; // `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}`;

export const api = axios.create({
  baseURL: `${URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

// 2024-10-13 12:54:28 Try to fix the "connection is not secure" warning in chrome, seems not working, worth a try in the express server
// function replaceHttpWithHttps(data: any): any {
//   if (typeof data === "string") {
//     return data.replace(/http:\/\//g, "https://");
//   }

//   if (Array.isArray(data)) {
//     return data.map((item) => replaceHttpWithHttps(item));
//   }

//   if (typeof data === "object" && data !== null) {
//     const newData: { [key: string]: any } = {};
//     for (const key in data) {
//       newData[key] = replaceHttpWithHttps(data[key]);
//     }
//     return newData;
//   }

//   return data;
// }

// api.interceptors.response.use((response) => {
//   // Modify the response data here
//   response.data = replaceHttpWithHttps(response.data);
//   return response;
// });
