import React from "react";
import MainContainer from "../components/layouts/MainContainer";
import MoreAlbums from "../components/MoreAlbums";

function Album() {
  return (
    <MainContainer>
      <MoreAlbums />
    </MainContainer>
  );
}

export default Album;
