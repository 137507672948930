import styled from "styled-components";
import { colors } from "../utils/styles/global.styles";

export const Wrapper = styled.div`
  width: inherit;
  max-width: inherit;
  height: 50vh;
  position: fixed;
  bottom: 0;
  z-index: 200;
  background: #322b2b; //${colors.lightOrange}; //rgb(0, 0, 0, 0.69); //
  padding-bottom: 10px;
  color: #ddd;
  overflow: auto;

  .close-comments {
    text-align: center;
    position: sticky;
    top: -1px;
    background: #322b2b;
    padding: 10px;
  }

  .title {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    padding: 10px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 10px;
      border-bottom: 1px solid rgb(45, 45, 45, 0.2);
      display: flex;
      align-items: flex-start;
    }
  }

  .user-avatar {
    margin-right: 10px;
    img {
      object-fit: cover;
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
  }

  .comment-container {
    width: 100%;
    .comment {
      font-size: 14px;
      .user-name {
        color: ${colors.lightBlue};
        padding-right: 5px;
      }
    }

    .dateInfo {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 5px;

      .thumb-up {
        display: flex;
        align-items: center;
      }
    }
  }

  .bottom-ref {
    text-align: center;
  }

  .loading-indicator {
    /* height: 80vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-size: 50px;
    }
  }
`;
