import styled from "styled-components";
import { colors } from "../utils/styles/global.styles";

export const Wrapper = styled.div`
  /* position: relative; */

  .title {
    /* font-size: 14px; */
    font-weight: 600;
    padding: 15px 20px;
    color: ${colors.darkGray};
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    cursor: pointer;

    li {
      width: 100%;
      padding: 13px 20px;
      display: flex;
      align-items: center;

      span:nth-child(1) {
        flex: 10%;
        font-size: 20px;
        max-width: 150px;
        display: flex;
        align-items: center;
        /* line-height: 20px;
        vertical-align: middle; */
      }

      span:nth-child(2) {
        flex: 90%;
      }

      &:hover,
      :active {
        background: #ddd;
      }
    }

    .album {
      color: #aaa;
    }
  }

  .download-link {
    display: none;
  }

  .cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 5px solid #eee;

    .cancel-inner {
    }
  }

  /* &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  } */
`;

export const Li = styled.li<{ mvId: number }>`
  color: ${({ mvId }) => (mvId ? "" : "#aaa")};
`
