import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { SongContext } from "../context/SongProvider";
import Carousel from "./Carousel";
import ConfirmModal from "./ConfirmModal";
import CurrentPlayList from "./CurrentPlayList";
import { Wrapper } from "./Modal.styles";
import MusicPlayPanel from "./MusicPlayPanel";
import PlaylistDescription from "./PlaylistDescription";
import SingleSongItemAction from "./SingleSongItemAction";
import UserProfile from "./UserProfile";
import VideoComments from "./VideoComments";

function Modal() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  // console.log("location within modal", location);

  const songContext = useContext(SongContext);

  if (!songContext) throw new Error();
  const { song, lyric } = songContext;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const { action } = e.currentTarget.dataset;
    if (action !== "profile") {
      history.goBack();
    }
  };

  let component;

  switch (location.search) {
    // case "?profile":
    //   component = (
    //     <div
    //       className="profile-modal-content"
    //       data-action="profile"
    //       onClick={handleClick}
    //     >
    //       <UserProfile />
    //     </div>
    //   );
    //   break;
    case "?current-playlist":
      component = (
        <div
          className="current-playlist-modal-wrapper"
          data-action="current-playlist"
          onClick={handleClick}
        >
          <div className="current-playlist-modal-content">
            <CurrentPlayList />
          </div>
        </div>
      );

      break;
    // case "?play-panel":
    //   component = (
    //     <div
    //       className="play-panel-modal-wrapper"
    //       data-action="play-panel"
    //       onClick={handleClick}
    //     >
    //       <MusicPlayPanel />
    //     </div>
    //   );
    //   break;
    case "?song-action":
      component = (
        <div
          className="song-action-modal-wrapper"
          data-action="song-action"
          onClick={handleClick}
        >
          <div className="song-action-modal-content">
            <SingleSongItemAction />
          </div>
        </div>
      );
      break;
    case "?delete-all":
      component = (
        <div
          className="delete-all-modal-wrapper"
          data-action="delete"
          onClick={handleClick}
        >
          <div className="delete-all-modal-content">
            <ConfirmModal />
          </div>
        </div>
      );
      break;
    // case "?video-comment":
    //   component = (
    //     <div
    //       // className="play-panel-modal-wrapper"
    //       data-action="video-comment"
    //       onClick={handleClick}
    //     >
    //       <VideoComments />
    //     </div>
    //   );
    //   break;
    case "?playlist-description":
      component = <PlaylistDescription />;
      break;
    default:
      console.error("Error, no such case");
  }

  return <Wrapper albumImage={song.image}>{component}</Wrapper>;
}

export default Modal;
