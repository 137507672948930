import { api } from "../services/api";

interface VideoCommentParams {
  id?: number;
  limit: number;
  offset?: number;
}

interface AllVideosParams {
  limit: number;
  offset?: number;
  area?: string;
  order?: string;
  type?: string;
}

// Could be replaced by getAllVideos below
// export const getTopVideos = (params: VideoCommentParams) => {
//   return api({
//     method: "get",
//     url: "/top/mv",
//     params,
//   });
// };

// For order param, the default is "上升最快", and the other option are "最热"， “最新”
// For type param, 可选值为全部，官方版，原生，现场版，网易出品，不填则为全部
//FIXME: 2021-12-28 15:08:43 Always got 400 error for different type params
export const getAllVideos = (params: AllVideosParams) => {
  return api({
    method: "get",
    url: "/mv/all",
    params,
  });
};

export const getVideoURL = (id: number) => {
  return api({
    method: "get",
    url: "/mv/url",
    params: {
      id,
    },
  });
};

export const getVideoDetails = (id: number) => {
  return api({
    method: "get",
    url: "/mv/detail",
    params: {
      mvid: id,
    },
  });
};

export const getVideoComments = (params: VideoCommentParams) => {
  return api({
    method: "get",
    url: "/comment/mv",
    params,
  });
};
