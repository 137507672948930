import React, { useState, useRef, createContext, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

enum PlayMode {
  LOOP = 0,
  SHUFFLE = 1,
  SINGLE_LOOP = 2,
}

interface PlayerContextType {
  playModeIndex: number;
  setPlayModeIndex: (
    playModeIndex: number | ((prevState: number) => number)
  ) => void;
  isMusicPlaying: boolean;
  setIsMusicPlaying: (
    isMusicPlaying: boolean | ((preState: boolean) => boolean)
  ) => void;
  playCurrentTime: number;
  setPlayCurrentTime: (playCurrentTime: number) => void;
  progressValue: number;
  setProgressValue: (progressValue: number) => void;
  lyric: string;
  setLyric: (lyric: string) => void;
  isPlayPanelShowing: boolean;
  setIsPlayPanelShowing: (isPlayPanelShowing: boolean) => void;
  clickedPlayerControlOption: { name: string; timestamp: number };
  setClickedPlayerControlOption: (clickedPlayerControlOption: {
    name: string;
    timestamp: number;
  }) => void;
  currentLyricIndex: number;
  setCurrentLyricIndex: (currentLyricIndex: number) => void;
  // handleNextSong: any;
  // handleShuffle: any;
  // handlePreviousSong: any;
  playerRef: any;
}

export const PlayerContext = createContext<PlayerContextType | null>(null);

export function PlayerProvider({ children }: Props) {
  const localPlayModeIndex = localStorage.getItem("playModeIndex");
  const [isModalActive, setIsModalActive] = useState({
    currentPlaylist: false,
    playPanel: false,
  });
  const [playModeIndex, setPlayModeIndex] = useState(
    localPlayModeIndex ? +JSON.parse(localPlayModeIndex) : PlayMode.LOOP
  );
  const [progressValue, setProgressValue] = useState(0);
  const [isPlayPanelShowing, setIsPlayPanelShowing] = useState(false);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [lyric, setLyric] = useState("");
  const [playCurrentTime, setPlayCurrentTime] = useState(0);
  const [clickedPlayerControlOption, setClickedPlayerControlOption] = useState({
    name: "",
    timestamp: 0,
  });
  const [currentLyricIndex, setCurrentLyricIndex] = useState(0);

  const playerRef = useRef<HTMLAudioElement>(null);

  localStorage.setItem("playModeIndex", JSON.stringify(playModeIndex));

  return (
    <PlayerContext.Provider
      value={{
        lyric,
        setLyric,
        isMusicPlaying,
        setIsMusicPlaying,
        playCurrentTime,
        setPlayCurrentTime,
        playModeIndex,
        setPlayModeIndex,
        progressValue,
        setProgressValue,
        isPlayPanelShowing,
        setIsPlayPanelShowing,
        clickedPlayerControlOption,
        setClickedPlayerControlOption,
        currentLyricIndex,
        setCurrentLyricIndex,
        // handleShuffle,
        // handlePreviousSong,
        // handleNextSong,
        playerRef,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
}
