import styled from "styled-components";

interface WrapperType {
  carouselIndex: number;
  isTouchEnd: boolean;
  moveLength: number;
}

export const NewWrapper = styled.div`
  height: 160px; //20vh;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  margin: 0 5px;
  //margin-top: 55px;
  cursor: pointer;
  z-index: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  @media (min-width: 600px) {
    height: 300px;
    //max-width: 800px;
    //margin: 0 auto;
  }
`;

export const Wrapper = styled.div<WrapperType>`
  display: flow-root;
  height: 160px; //20vh;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  margin: 0 5px;
  margin-top: 55px;

  .carousel-track {
    min-height: 160px;
    display: flex;
    position: relative;

    transition: 0.5s transform ease-in-out;
  }

  @media (min-width: 600px) {
    height: 300px;
    //max-width: 800px;
    //margin: 0 auto;
  }
`;

export const CarouselItem = styled.div<{ isActive: boolean }>`
  flex: 0 0 100%;
  height: auto;
  background: #ccc;
  display: ${({ isActive }) => (isActive ? "block" : "none")};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
`;

export const SlideControl = styled.div`
  font-size: 30px;
  position: absolute;
  bottom: 5px; //15vh
  width: 100%;

  .dot-list {
    width: max-content;
    margin: 0 auto;
  }

  span {
  }
`;

export const SlideIndicator = styled.span<{ isActive: boolean }>`
  display: inline-block;
  width: 10px;
  height: 10px;
  background: ${({ isActive }) => (isActive ? "#fff" : "#bbb")};
  border-radius: 50%;
  transition: all 0.6s ease;
  cursor: pointer;
  margin: 0 5px;

  /* &:hover {
    background: #fff;
  } */
`;
