import styled from "styled-components";
import { colors } from "../utils/styles/global.styles";

export const Wrapper = styled.div`
  padding: 0 15px 15px;
  /* margin: 60px 0; */
  position: fixed;
  top: 0;
  bottom: 0;
  width: inherit;
  max-width: inherit;
  height: 100%;
  background: ${colors.white};
  z-index: 5;
  overflow: auto;

  .header {
    display: flex;
    position: sticky;
    top: 0;
    background: ${colors.white};
    color: #bbb;
    padding: 10px;
    font-size: 25px;
    justify-content: flex-start;

    .back-icon {
      flex: 0 1 40%;
    }

    .page-title {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .artist-identity {
    display: flex;

    .artist-short {
      flex: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .artist-name {
        font-size: 18px;
        font-weight: 600;
        color: #aaa;
        cursor: pointer;

        span {
          /* content: ""; */
          /* display: block; */
          background: linear-gradient(to top, red, #ffba00);
          background-position: 2px 15px;
          background-repeat: no-repeat;
          padding-bottom: 5px;
          padding-right: 2px;
          /* background-size: 2px 6px; */
          /* height: 10px; */

          &:active {
            background: ${colors.clickEffect};
            transition: 0.1s;
          }
        }
      }

      .artist-music {
        padding: 20px 0;
        line-height: 2;
        font-size: 14px;
      }
    }

    .artist-cover {
      flex: 60%;
      max-width: 50%;
      max-height: 200px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .title {
    font-weight: 600;
    color: #222;
    padding: 10px 0;
  }

  .content {
    font-size: 14px;
    line-height: 1.6;
    text-align: justify;

    .split-content {
      padding: 5px;
    }
  }

  .loading-indicator {
    padding: 10px 0;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-size: 50px;
    }
  }
`;
