import { openDB } from "idb";

export const writeToIndexedDB = async (
  datastore: string,
  data: { [key: string]: string },
  key: string
) => {
  const db = await openDB(datastore + "-db", 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(datastore)) {
        db.createObjectStore(datastore);
      }
    },
  });

  return db.put(datastore, data, key);
};

export const getFromIndexedDB = async (datastore: string, key: string) => {
  const db = await openDB(datastore + "-db", 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(datastore)) {
        db.createObjectStore(datastore);
      }
    },
  });

  return db.get(datastore, key);
};
