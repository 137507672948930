import styled from "styled-components";
import { colors } from "../utils/styles/global.styles";

export const Wrapper = styled.div`
  .next-previous-buttons {
    display: flex;
    justify-content: flex-end;

    .icon {
      padding: 3px 10px;
      margin: 0 10px;
      background: ${colors.darkWhite};
      border: none;
    }
  }
  .category-title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    padding-top: 20px;

    .indicator {
      display: inline-block;
      width: 8px;
      height: 20px;
      background: red;
      margin-right: 10px;
    }

    .title {
      font-weight: 600;
    }
  }

  .playlist-category {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0;
  }
`;
