import styled from "styled-components";
import { colors } from "../utils/styles/global.styles";

export const Wrapper = styled.div`
  padding: 0 10px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 14px;
  }
  .title {
    font-weight: 600;
    padding: 10px 0;
  }

  .history-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${colors.darkGray};
  }

  .hot-title {
    color: red; //;
  }

  .search-history {
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      padding: 6px 15px;
      background-color: #ddd;
      margin: 5px;
      border-radius: 15px;
      cursor: pointer;

      &:active {
        background: ${colors.clickEffect};
        transition: 0.1s;
      }
    }
  }

  .search-hot {
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      width: 45%;
      padding: 6px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:active {
        background: ${colors.clickEffect};
        transition: 0.1s;
      }
    }
    .hot-index {
      width: 20px;
    }

    .hot-item {
      flex: 100%;
      padding-left: 10px;
    }
  }
`;
