import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./utils/i18next/i18n";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as ServiceWorker from "./serviceWorkerRegistration";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { on } from "process";

Sentry.init({
  dsn:
    "https://43eac8eb94c943f4bdf8f03a2bfa29ba@o390658.ingest.sentry.io/5573472",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // debug: true,
});


const nrhAscii = `
 _   _      _       __  __           _      
| \\ | |_ __| |__   |  \\/  |_   _ ___(_) ___ 
|  \\| | '__| '_ \\  | |\\/| | | | / __| |/ __|
| |\\  | |  | | | | | |  | | |_| \\__ \\ | (__ 
|_| \\_|_|  |_| |_| |_|  |_|\\__,_|___/_|\\___|
                                            
Welcome to Nrh Music!   -- By Ruihua
  `;

// `
//  _   _ ____  _   _
// | \\ | |  _ \\| | | |
// |  \\| | |_) | |_| |
// | |\\  |  _ <|  _  |
// |_| \\_|_| \\_\\_| |_|

//   `;

console.log(
  "%c" + nrhAscii,
  "font-family: monospace; font-size: 16px; font-weight: bold;"
);

if (process.env.NODE_ENV == "development") {
  console.log = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

ServiceWorker.register();
