import React from "react";
import MusicPlayPanel from "../components/MusicPlayPanel";

function Panel() {
  return (
    <>
      <MusicPlayPanel />
    </>
  );
}

export default Panel;
