import React, { useState, useEffect, ReactNode, useReducer } from "react";
import { createContext } from "react";
import { useLocation } from "react-router-dom";
import { getArtistList } from "../api/artist";
import { getBanners } from "../api/others";
import {
  getRecommendedPlaylist,
  getTopPlaylist,
  getTrendPlaylist,
} from "../api/playlist";
import { api } from "../services/api";
import { shuffleArray } from "../utils/shuffle";

interface Props {
  children: ReactNode;
}

interface AlbumContextType {
  // musicAlbums: MusicAlbum;
  // setMusicAlbums: (
  //   musicAlbums: MusicAlbum | ((prev: MusicAlbum) => MusicAlbum)
  // ) => void;
  selectedFilter: string;
  setSelectedFilter: (selectedFilter: string) => void;
  selectedOrder: string;
  setSelectedOrder: (selectedOrder: string) => void;
  selectedType: string;
  setSelectedType: (selectedType: string) => void;
}

export const AlbumContext = createContext<AlbumContextType | null>(null);

export function AlbumProvider({ children }: Props) {
  const [selectedFilter, setSelectedFilter] = useState("全部");
  const [selectedOrder, setSelectedOrder] = useState("最热");
  const [selectedType, setSelectedType] = useState("全部");

  return (
    <AlbumContext.Provider
      value={{
        selectedFilter,
        setSelectedFilter,
        selectedOrder,
        setSelectedOrder,
        selectedType,
        setSelectedType,
      }}
    >
      {children}
    </AlbumContext.Provider>
  );
}
