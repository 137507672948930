import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { AiOutlineFire, AiOutlineMore } from "react-icons/ai";
import { VscArrowLeft } from "react-icons/vsc";
import { RiWalkLine, RiArrowDownLine } from "react-icons/ri";
import dayjs from "dayjs";
import { api } from "../services/api";
import { Wrapper } from "./Comments.styles";
import { throttle } from "../utils/throttleAndDebunce";
import { MessageContext } from "../context/MessageProvider";
import { getMusicHotComments } from "../api/others";
import { useTranslation } from "react-i18next";

interface LocationType {
  state: {
    songId: number;
  };
}

export interface Comment {
  content: string;
  time: number;
  likedCount: number;
  commentId: number;
  user: {
    nickname: string;
    avatarUrl: string;
  };
}
function Comments() {
  const { t } = useTranslation();
  const [userComments, setUserComments] = useState<Comment[]>([]);
  //const bottomRef = useRef<HTMLDivElement>(null);
  const location: LocationType = useLocation();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  // console.log(location);

  const messageContext = useContext(MessageContext);

  if (!messageContext) throw new Error();

  const { dispatch } = messageContext;

  const fetchComments = async (id: number, offset: number) => {
    const response = await getMusicHotComments({
      type: 0,
      id,
      offset: offset * 20,
    }); // default limit is 20
    // api.get(`/comment/hot?type=0&id=${id}&offset=${offset * 20}`);
    //console.log(response);
    setUserComments((prev) => [...prev, ...response.data.hotComments]);
    if (response.data.hotComments.length === 0) {
      if (offset === 0) {
        dispatch({ type: "noComment-error" });
        setTimeout(() => {
          dispatch({ type: "none" });
        }, 3000);
      } else {
        dispatch({ type: "noMoreComment-error" });
        setTimeout(() => {
          dispatch({ type: "none" });
        }, 3000);
      }
    }
  };

  useEffect(() => {
    if (params.id) {
      fetchComments(+params.id, 0);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  // useEffect(() => {
  //   let i = 0;
  //   console.log("bottomRef.current is", bottomRef.current, userComments.length);

  //   const intersectionObserver = new IntersectionObserver((entries) => {
  //     console.log("observer is triggered", entries[0].isIntersecting);
  //     if (entries[0].isIntersecting) {
  //       // console.log("offset before fetch", offset, i);
  //       i++;
  //       fetchComments(location.state.songId, i);
  //     }
  //   });
  //   if (bottomRef.current && userComments.length)
  //     intersectionObserver.observe(bottomRef.current);
  //   // let i = 0;
  //   // let condition = true;
  //   // const lazyLoadComments = () => {
  //   //   // console.log(
  //   //   //   window.innerHeight + window.pageYOffset >=
  //   //   //     document.body.offsetHeight + 100 // 105 is the height of search bar and play bar. because it seems body.offsetHeight will ignore fixed elements' height
  //   //   // );
  //   //   if (
  //   //     window.innerHeight + window.pageYOffset >=
  //   //     document.body.offsetHeight + 100
  //   //   ) {
  //   //     i++;
  //   //     if (condition) {
  //   //       fetchComments(location.state.songId, i);
  //   //     }

  //   //     condition = false;
  //   //   } else {
  //   //     condition = true;
  //   //   }
  //   // };
  //   // const throttleScroll = throttle(lazyLoadComments, 500);

  //   // window.addEventListener("scroll", throttleScroll);

  //   // return () => window.removeEventListener("scroll", throttleScroll);
  // }, [bottomRef.current]);

  const bottomRef = useCallback((node) => {
    let i = 0;
    // console.log(node);

    const intersectionObserver = new IntersectionObserver((entries) => {
      // console.log("observer is triggered", entries[0].isIntersecting);
      if (entries[0].isIntersecting) {
        // console.log("offset before fetch", offset, i);
        i++;
        fetchComments(+params.id, i);
      }
    });
    if (node) intersectionObserver.observe(node);
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const { action } = e.currentTarget.dataset;
    console.log(action);

    switch (action) {
      case "backward":
        history.goBack();
        break;
      case "settings":
        break;
      default:
        console.error("Error, no such action case " + action);
    }
  };

  return (
    <Wrapper>
      <div className="header">
        <div className="back-icon" data-action="backward" onClick={handleClick}>
          <VscArrowLeft />
        </div>
        <div className="title">{t("common.hot-comment")} </div>
        {/* <div className="settings" data-action="settings" onClick={handleClick}>
          <AiOutlineMore />
        </div> */}
      </div>
      <ul>
        {userComments.length ? (
          <>
            {userComments.map((comment, index) => (
              <li key={comment.commentId}>
                <div className="user-avatar">
                  <img src={comment.user.avatarUrl + "?param=60y60"} />
                </div>
                <div className="comment-container">
                  <div className="comment">
                    <span className="user-name">{comment.user.nickname}:</span>
                    <span>{comment.content}</span>
                  </div>
                  <div className="dateInfo">
                    <span>
                      {dayjs(comment.time).format("YYYY-MM-DD HH:mm")}
                    </span>
                    <span className="thumb-up">
                      <AiOutlineFire />
                      {comment.likedCount}
                    </span>
                  </div>
                </div>
              </li>
            ))}
            <div ref={bottomRef} className="dot-indicator">
              {/* <RiArrowDownLine /> */}
              ...
            </div>
          </>
        ) : (
          <div className="loading-indicator">
            <span className="loading-indicator-icon">
              <RiWalkLine />
            </span>
            <p>{t("common.loading")}</p>
          </div>
        )}
      </ul>
      {/* <div ref={bottomRef} className="bottom-ref">
        {userComments.length? <RiArrowDownLine /> :null} 
      </div> */}
    </Wrapper>
  );
}

export default Comments;
