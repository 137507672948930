import styled from "styled-components";
import { colors, loadingAnimation } from "../utils/styles/global.styles";

export const Wrapper = styled.div`
  //width: 100%;
  //min-height: calc(100vh - 125px);
  position: fixed;
  top: 0;
  bottom: 0;
  width: inherit;
  max-width: inherit;
  height: 100%;
  z-index: 5;
  background: rgb(60, 60, 60, 1); // ${colors.lightOrange};
  padding: 0 10px 10px 10px;
  color: #ddd;
  overflow: auto;

  .header {
    display: flex;
    position: sticky;
    top: 0;
    background: ${colors.brown};
    color: #bbb;
    padding: 10px;
    font-size: 25px;
    justify-content: flex-start;

    .back-icon {
      flex: 0 1 40%;
    }
  }

  .title {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    padding: 10px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 10px;
      border-bottom: 1px solid rgb(45, 45, 45, 0.2);
      display: flex;
      align-items: flex-start;
    }
  }

  .user-avatar {
    margin-right: 10px;
    img {
      object-fit: cover;
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
  }

  .comment-container {
    width: 100%;
    .comment {
      font-size: 14px;
      .user-name {
        color: ${colors.lightBlue};
        padding-right: 5px;
      }
    }

    .dateInfo {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 5px;

      .thumb-up {
        display: flex;
        align-items: center;
      }
    }
  }

  /* .bottom-ref {
    text-align: center;
  } */
  .dot-indicator {
    margin: auto;
    text-align: center;
    width: 50%;
    font-size: 20px;
    position: relative;
    animation: ${loadingAnimation} 3s linear 0s infinite;
  }

  .loading-indicator {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .loading-indicator-icon {
      font-size: 50px;
      position: relative;
      animation: ${loadingAnimation} 3s linear 0s infinite;
    }
  }
`;
