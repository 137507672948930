import React, { useContext, useEffect } from "react";
import { RiRidingLine, RiArrowRightSLine } from "react-icons/ri";
import { List, Wrapper } from "./MusicLists.styles";
import { Link, useHistory } from "react-router-dom";
//import { AlbumContext } from "../context/AlbumProvider";
import { SongContext } from "../context/SongProvider";
import { useTranslation } from "react-i18next";
import {
  getPlaylistDetails,
  getRecommendedPlaylist,
  getTopPlaylist,
  getTrendPlaylist,
} from "../api/playlist";
import { useQueries, useQueryClient } from "react-query";
import { getArtistList } from "../api/artist";
import { getAllVideos } from "../api/video";
import { BsCaretRight } from "react-icons/bs";

interface MusicAlbumItem {
  id: number;
  name: string;
  coverImgUrl: string;
  picUrl: string;
  cover?: string;
  artistId?: number;
}

interface MusicAlbums {
  [key: string]: {
    name: string;
    data: MusicAlbumItem[];
  };
}

export const albumsEndPoints = {
  trends: "list",
  hot: "playlists",
  recommended: "result",
  artists: "artists",
  mv: "data",
};

// const categoryNames = ["recommend", "hot", "handpick","artists"]
function MusicLists() {
  const { t } = useTranslation();
  const history = useHistory();
  //const albumContext = useContext(AlbumContext);
  const songContext = useContext(SongContext);

  if (!songContext) throw new Error();
  //const { musicAlbums, setMusicAlbums } = albumContext;
  const { setPlayListId, setPlayList, setAlbumInfo } = songContext;

  const queryClient = useQueryClient();

  const results = useQueries([
    { queryKey: ["trendsAlbums"], queryFn: () => getTrendPlaylist() },
    {
      queryKey: ["hotAlbums"],
      queryFn: () => getTopPlaylist({ order: "hot", limit: 6 }),
    },
    {
      queryKey: ["recommendedAlbums"],
      queryFn: () => getRecommendedPlaylist({ limit: 6 }),
    },
    {
      queryKey: ["artistAlbums"],
      queryFn: () =>
        getArtistList({ type: -1, area: "-1", initial: -1, limit: 6 }),
    },
    {
      queryKey: ["hotMvs"],
      queryFn: () => getAllVideos({ order: "最热", limit: 6 }),
    },
  ]);

  //console.log("results", results);

  const [
    trendsResponse,
    hotResponse,
    recommendedResponse,
    hotArtistsResponse,
    mvResponse,
  ] = results;

  const musicAlbums: MusicAlbums = {
    trends: {
      name: "trends",
      data: trendsResponse.data?.data[albumsEndPoints.trends],
    },
    hot: {
      name: "hot",
      data: hotResponse.data?.data[albumsEndPoints.hot],
    },
    recommended: {
      name: "recommended",
      data: recommendedResponse.data?.data[albumsEndPoints.recommended],
    },
    artists: {
      name: "artists",
      data: hotArtistsResponse.data?.data[albumsEndPoints.artists],
    },
    mv: {
      name: "mv",
      data: mvResponse.data?.data[albumsEndPoints.mv],
    },
  };

  //Prefetch albums to speed up the app by using cache
  //  useEffect(() => {
  //    const concatAlbums = Object.values(musicAlbums)?.flatMap(
  //      (item) => item.data.slice(0,6)
  //    );
  //    console.log(" concatAlbums", concatAlbums)
  //    concatAlbums.forEach((item,index) => {
  //      setTimeout(() => {
  //        //console.log("trigger", item.id);
  //        queryClient.prefetchQuery(["playlistInfo", item.id], () =>
  //          getPlaylistDetails(item.id)
  //        );
  //      }, 1000*index);

  //    });

  //  }, [musicAlbums]);;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const { action, name, id, artistid } = e.currentTarget.dataset;

    switch (action) {
      case "album":
        if (id) {
          setPlayListId(+id);
          setPlayList([]); // Reset playlist before fetching new playlist
          setAlbumInfo(undefined); // Reset albumInfo before jumping to album details page

          history.push({
            pathname: `/playlist/${name}/${id}`,
            // state: { category: name },
          });
        }

        break;
      case "mv":
        if (id && artistid) {
          history.push({
            pathname: `/video/${artistid}/${id}`,
            // state: { mvId: mvid, artistId: artistid },
          });
        }
        break;
      case "more":
        history.push({
          pathname: `/albums/${name}`,
          //search: name === "artists" ? "-1" : "?全部",
          // state: { category: name },
        });
        break;
      default:
        console.error("Error, no such case");
    }
  };

  if (results.some((item) => item.isLoading)) {
    return (
      <Wrapper>
        <div className="loading-indicator">
          <span>
            <RiRidingLine />
          </span>
          <p>{t("common.loading")}</p>
        </div>
      </Wrapper>
    );
  }

  if (results.some((item) => item.isError)) {
    return (
      <div>
        <h2>{JSON.stringify(results.some((item) => item.error))}</h2>
      </div>
    );
  }

  return (
    <Wrapper>
      {Object.values(musicAlbums).map((item, index) => (
        <div className="container" key={index}>
          <div className="category-area">
            <span className="category-indicator"></span>
            <span className="category-title">{t(`home.${item.name}`)}</span>
            <span
              className="category-more"
              data-action="more"
              data-name={item.name}
              onClick={handleClick}
            >
              {t("home.more")}
              <span>
                <RiArrowRightSLine />
              </span>
            </span>
          </div>
          <div className={`playlist-category ${item.name}`}>
            {item.data.length &&
              item.data.slice(0, 6).map((playlist) => (
                <List
                  key={playlist.id}
                  data-name={item.name}
                  data-id={playlist.id}
                  data-artistid={playlist.artistId}
                  data-action={item.name === "mv" ? "mv" : "album"}
                  onClick={handleClick}
                >
                  <div className="image-container">
                    <img
                      src={
                        (playlist.coverImgUrl ||
                          playlist.picUrl ||
                          playlist.cover) + "?param=300y300"
                      }
                      alt={item.name}
                    />
                    {item.name === "mv" && (
                      <div className="video-icon">
                        <BsCaretRight />
                      </div>
                    )}
                  </div>
                  <div className="short-description">{playlist.name}</div>
                </List>
              ))}
          </div>
        </div>
      ))}
    </Wrapper>
  );
}

export default MusicLists;
